import styled from 'styled-components';

export const LogoImage = styled.img`
  height: 100%;
  padding: 8px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust spacing between title and button */
`;