import React, { useState } from 'react';
import * as S from './style';
import { FaEye, FaEyeSlash, FaRegEnvelope, FaRegQuestionCircle } from 'react-icons/fa';
import { IoPersonOutline } from 'react-icons/io5';
import { IoLockClosedOutline } from 'react-icons/io5';
import { IoMdBook } from 'react-icons/io';
import { gqlSchema } from '../../../../gql/schema';
import { useMutation } from '@apollo/client';
import { message, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

interface SignUpProps {
  onActionBtnClick: (nextActivePanel: 'signin' | 'signup') => void;
  transitionState: string;
}
const SignUp: React.FC<SignUpProps> = ({ onActionBtnClick, transitionState }) => {
  const history  = useHistory()
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [bookCode, setBookCode] = useState('');
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [createBookUser] = useMutation(gqlSchema.BookSchema.mutations.CreateBookUser, {
    onCompleted: () => {
     

      message.success('User Signed up successfully!');
      setTimeout(() => {
        history.push('/?active=signin');
      }, 2000);
    },
    onError: (error) => {
   
      message.error('An account with this email address already exists. Please sign in or use a different email');
      console.log('Errorr', error);
     
    },
  });
  const validatePassword = (password: string): boolean => {
    const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[$@!#%.:,()])\\S{12,50}$');
    if (!passwordRegex.test(password)) {
      return false;
    }
    return true;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSignUp = () => {
    if (!(checked1 && checked2 )) {
      return message.error('Please agree to the Terms of Use and Privacy Policy');
    }
    if(!(firstName && lastName)){
      return message.error('Please enter your first and last name');
    }
    if (password.length < 12) {
      return message.error('The minimum password length is 12 characters');
    }
    if (password !== confirmPassword) {
      return message.error('Passwords do not match');
    }
    if (!validatePassword(password)) {
      return message.error('Password should be atleast 12 characters long, containing at least 1 uppercase letter, 1 lowercase letter and 1 special character (@, #, $, !)');
    }
    console.log(checked1);
    localStorage.setItem('BookCode', bookCode);
    createBookUser({
      variables: {
        data: {
          firstName,
          lastName,
          email,
          password,
          metadata: '',
        },
      },
    });
  };
  return (
    <S.FormContainer>
      <S.FormHeader>
        Sign Up to Create an <span style={{ color: '#667eea' }}> INDIVIDUAL </span>Account
      </S.FormHeader>

      <S.NameContainer>
        <S.InputGroup>
          <IoPersonOutline />
          <S.Input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            placeholder="First Name"
            required
          />
        </S.InputGroup>

        <S.InputGroup>
          <IoPersonOutline />
          <S.Input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            placeholder="Last Name"
            required
          />
        </S.InputGroup>
      </S.NameContainer>

      <S.InputGroup>
        <FaRegEnvelope style={{ opacity: 0.6 }}/>
        <S.Input value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" required />
      </S.InputGroup>

      <S.NameContainer>
        <S.InputGroup>
          <IoLockClosedOutline />
          <S.Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            placeholder="Create Password"
            required
          />
        </S.InputGroup>
        <S.InputGroup>
        <IoLockClosedOutline />
        <S.Input
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            placeholder="Confirm Password"
            required
          />
          <Tooltip title={'Your password should be atleast 12 characters long, containing at least 1 uppercase letter, 1 lowercase letter and 1 special character (@, #, $, !)'}>
            <FaRegQuestionCircle style={{ left: 'auto', right: '10px', opacity: 0.6 }} />
          </Tooltip>
          <div onClick={togglePasswordVisibility}
            style={{ cursor: 'pointer', position: 'absolute', right: '75px', top: '30%', transform: 'translateY(-50%)' }}>
            {showPassword ? <FaEyeSlash style={{ opacity: 0.6 }}/> : <FaEye style={{ opacity: 0.6 }}/>}
          </div>
        </S.InputGroup>
      </S.NameContainer>

      <S.InputGroup>
        <IoMdBook />
        <S.Input
          value={bookCode}
          onChange={(e) => setBookCode(e.target.value)}
          type="text"
          placeholder="Book Code"
          required
        />
        <Tooltip title={'Enter the book code that was sent to you with you book.  If you do not have a book code leave this field blank.  You will be able to enter a book code after you create an account'}>
          <FaRegQuestionCircle style={{ left: 'auto', right: '10px', opacity: 0.6 }} />
        </Tooltip>
      </S.InputGroup>

      <S.CheckboxContainer>
        <S.Checkbox checked={checked1} type="checkbox" onChange={(e) => setChecked1(e.target.checked)} />
        <span>
          I understand and agree to the ADI Learning Hub{' '}
          <a href="https://adilearninghub.com/documents/terms-of-use.pdf" target="_blank" rel="noopener noreferrer">
            Terms of Use
          </a>
        </span>
      </S.CheckboxContainer>

      <S.CheckboxContainer>
        <S.Checkbox checked={checked2} type="checkbox" onChange={(e) => setChecked2(e.target.checked)} />
        <span>
          I understand and agree to the ADI Learning Hub{' '}
          <a href="https://adilearninghub.com/documents/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </span>
      </S.CheckboxContainer>
      <S.ButtonContainer>
        <S.Button style={{ width: '100%' }} onClick={handleSignUp}>
          CREATE MY NEW ACCOUNT
        </S.Button>
      </S.ButtonContainer>
    </S.FormContainer>
  );
};

export default SignUp;
