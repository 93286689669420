import React, { useState } from 'react';
import { Typography, Form, Checkbox, message, Spin } from 'antd';
import * as S from './styles';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../../gql/schema';
import Button from '../../../../../shared/Button';

interface BookDetailsProps {
  bookCode: string;
  onClose?: (refetch?: Boolean) => void;
}
const BookDetails: React.FC<BookDetailsProps> = ({ bookCode, onClose }) => {
  const { loading, error, data } = useQuery(gqlSchema.BookSchema.queries.FetchBookByBookCode, {
    variables: {
      id: bookCode,
    },
    onError: () => {
      message.error("The book code you have entered is invalid. Please check and try again");
      localStorage.removeItem('BookCode');
    },
  });
  const [isAgree, setIsAgree] = useState(false);

  const [addBookUser] = useMutation(gqlSchema.BookSchema.mutations.AddBookUser, {
    onCompleted: () => {
      message.success('Book has been added successfully!');
      if (onClose) {
        onClose(true);
      }
    },
    onError: (error) => {
      console.error(error);
      message.error(<Typography.Text data-cy="components-booksignup-error-text">{error.message}</Typography.Text>);
    },
  });
  const onProceed = () => {
    if (!data.fetchBookByBookCode.id) return;
    addBookUser({
      variables: {
        bookCode: bookCode,
      },
    });
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (error) {
    return <div>Error loading book details.</div>;
  }

  const book = data.fetchBookByBookCode;

  return (
    <S.Container>
      <S.ModalWrapper>
        <S.Title>Add the investigations from your book to your library </S.Title>
        <S.ContentRow>
          <S.LeftSection>
            <img src={book.imageUrl[0]} alt="Book" />
          </S.LeftSection>
          <S.RightSection>
            <S.BookDteailsWrapper style={{ gridArea: 'head' }}>
              <S.DetailsTitle>Book Name</S.DetailsTitle>
              <S.DetailsText>{book.title}</S.DetailsText>
            </S.BookDteailsWrapper>
            <S.BookDteailsWrapper style={{ gridArea: 'aside' }}>
              <S.DetailsTitle>Publisher</S.DetailsTitle>
              <S.DetailsText>{book.publisher || "Published"}</S.DetailsText>
            </S.BookDteailsWrapper>
            <S.BookDteailsWrapper style={{ gridArea: 'main' }}>
              <S.DetailsTitle>Publication Year</S.DetailsTitle>
              <S.DetailsText>{book.publishedYear || "0"}</S.DetailsText>
            </S.BookDteailsWrapper>
            <S.BookDteailsWrapper style={{ gridArea: 'foot' }}>
              <S.DetailsTitle>Subject</S.DetailsTitle>
              <S.DetailsText>
              {book.subject}
              </S.DetailsText>
            </S.BookDteailsWrapper>
            <S.BookDteailsWrapper style={{ gridArea: 'foot1' }}>
              <S.DetailsTitle>Investigation</S.DetailsTitle>
              <S.DetailsText>{book.investigation || "0"}</S.DetailsText>
            </S.BookDteailsWrapper>
            <Form style={{ gridArea: 'footer' }}>
              <Form.Item>
                <Checkbox checked={isAgree} onChange={(e) => setIsAgree(e.target.checked)} style={{ fontSize: '14px' , fontWeight: 'bold' ,maxWidth: '500px',textAlign: 'justify' }}>
                  I understand that I cannot return the hard copy of the book I purchased once I add the investigations
                  from this book into my ADI Learning Hub library.
                </Checkbox>
              </Form.Item>
              <S.Footer>
                <Button text="ADD THE BOOK INVESTIGATIONS" onClick={onProceed} disabled={!isAgree} block minWidth={300}  />
              </S.Footer>
            </Form>
          </S.RightSection>
        </S.ContentRow>
      </S.ModalWrapper>
    </S.Container>
  );
};

export default BookDetails;
