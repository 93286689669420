/* eslint-disable complexity */
import { Checkbox, Col, Popconfirm, Result, Row, Tooltip, message } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Button from '../../../shared/Button';
import {
  GQL_InvestigationActivity,
  GQL_InvestigationBlock,
  GQL_InvestigationStep,
  IBudgetCalculatorContentValue,
  IButtonContentValue,
  ICanvasContentValue,
  IConceptEvaluationContentValue,
  IMediaContentValue,
  InvestigationType,
  IOListContentValue,
  IPeerReviewContentValue,
  IReflectionContentValue,
  IReflectionQuestionnaireContentValue,
  ISortingBoardContentValue,
  ICategoryDragContentValue,
  ITextContentValue,
  ITextSubmitContentValue,
  IUploadContentValue,
  IProcessDragDropAnswer,
} from '../../../types/investigation';
import { formatDateTime } from '../../../utils/date';
import InvestigationButton from '../InvestigationButton';
import InvestigationComment from '../InvestigationComment';
import InvestigationImage from '../InvestigationImage';
import InvestigationOList from '../InvestigationOList';
import InvestigationQuestionnaireReflections from '../InvestigationQuestionnaireReflections';
import InvestigationReflections from '../InvestigationReflections';
import InvestigationReviews from '../InvestigationReviews';
import InvestigationSimulation from '../InvestigationSimulation';
import InvestigationText from '../InvestigationText';
import InvestigationTextSubmit from '../InvestigationTextSubmit';
import InvestigationUpload from '../InvestigationUpload';
import InvestigationVideo from '../InvestigationVideo';
import * as S from './styles';
import timesupImage from '../../../assets/timesup.svg';
import InvestigationBudgetCalculator from '../InvestigationBudgetCalculator';
import InvestigationSortingBoard from '../InvestigationSortingBoard';
import { InvestigationCanvas } from '../InvestigationCanvas';
import { useAuth } from '../../../hooks/useAuth';
import InvestigationTextImage from '../InvestigationTextImage';
import MultipleChoiceQuestionPreview from '../../AdiTemplatesPage/PreviewAssessmentModal/MultipleChoiceQuestionPreview';
import MultipleChoicePictureQuestionPreview from '../../AdiTemplatesPage/PreviewAssessmentModal/MultipleChoicePictureQuestionPreview';
import AssessmentProccessBoard from '../AssessmentProccessBoard';
import AssessmentCategoryBoard from '../AssessmentCategoryBoard';




interface IInvestigationContentBuilder {
  canProceed?: boolean;
  cantProceedReason?: string;
  investigationDuedate?: number;
  onActivitySubmit?: (payload?: { acknowledgedPresentationMessage?: boolean }) => void;
  loading?: boolean;
  onCommentWrite?: (written: boolean) => void;
  onBoardSorted?: (sorted: boolean) => void;
  onSelected?: (selected: boolean) => void;
  currentContentIndex: number;
  setCurrentContentIndex: (index: number) => void;
  currentActivity?: GQL_InvestigationActivity;
  currentActivityId?: string;
  currentStepId: string;
  currentStep?: GQL_InvestigationStep;
  preview?: boolean;
  classId?: string;
  investigationId?: string;
  selectedStudentId?: string;
  defaultActiveComment?: string;
  buildInPersonContent?: boolean;
  presentationMode?: boolean;
  isAssessment?: boolean;
  investigationType?: InvestigationType;
  contentSize?: string;
  previousSubmissionActivityId?: string;
  isLocked?: boolean;
  submissionVersion: number;
  showNextStepButton?: boolean;
  conciseForm?: boolean; // for use in grading and summary views
  courseId?: string;
  courseWorkId?: string;
}

const InvestigationContentBuilder = (props: IInvestigationContentBuilder) => {
  const {
    canProceed,
    onActivitySubmit,
    loading,
    onCommentWrite = () => null,
    onBoardSorted = () => null,
    currentContentIndex,
    setCurrentContentIndex,
    currentActivity,
    currentActivityId,
    currentStepId,
    currentStep,
    classId,
    preview,
    investigationDuedate,
    investigationId,
    selectedStudentId,
    defaultActiveComment,
    buildInPersonContent,
    cantProceedReason,
    presentationMode,
    isAssessment,
    investigationType,
    contentSize,
    previousSubmissionActivityId,
    isLocked = false,
    showNextStepButton = true,
    onSelected,
    submissionVersion,
  } = props;
  const { isStudent, user } = useAuth();
  const isGoogleStudent = user?.preferredRole === 'google_student';
  const isCanvasStudent = user?.preferredRole === 'canvas_student';
  const currentContent = useMemo(
    () => (buildInPersonContent ? currentActivity?.contentInPerson : currentActivity?.content) || [],
    [buildInPersonContent, currentActivity],
  );
  const [dontShowAgainChecked, setDontShowAgainChecked] = useState(false);


  

  const showForwardButton = currentContent?.length && currentContentIndex < currentContent.length - 1;
  
  // We are showing the proceed button if the user can proceed or if it's on the last content of activity
  const showProceedButton = canProceed || currentContentIndex === (currentContent?.length ?? 1) - 1;

  useEffect(() => {
    if (currentActivityId && isLocked) {
      message.info("This assessment is locked, you're not allowed to interact with it.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investigationId]);

  const buildContentBlock = useCallback(
    (block: GQL_InvestigationBlock) => {
      if (selectedStudentId && block.userId && block.userId !== selectedStudentId) return;
      const span = block.columns && block.type !== 'Canvas' ? 24 / block.columns : 24;
      const canvasIndex = currentContent.reduce(
        (acc, curr, index) =>
          index > currentContentIndex ? acc : acc + Number(curr.blocks.some((b) => b.type === 'Canvas')),
        -1,
      );

      const blockTypesAllowedInConciseForm = [
        'MultipleChoicePictureQuestion',
        'MultipleChoiceQuestion',
        'CategoryDragPicture',
        'CategoryDrag',
        'ProcessDragDropPictureAnswer',
        'ProcessDragDropAnswer',
        'TextSubmit',
      ];

      // only show the allowed blocks in concise form
      if (props.conciseForm && !blockTypesAllowedInConciseForm.includes(block.type)) {
        return null;
      }

      switch (block.type) {
        case 'Text': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              {<InvestigationText value={value as ITextContentValue} />}
            </S.Column>
          ));
        }
        case 'O-List': {
          return (
            <S.Column span={span}>{<InvestigationOList values={block.values as IOListContentValue[]} />}</S.Column>
          );
        }
        case 'Image': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${(value as IMediaContentValue).url}${i}`}>
              {
                <InvestigationImage
                  value={value as IMediaContentValue}
                  investigationType={investigationType}
                  contentSize={contentSize}
                />
              }
            </S.Column>
          ));
        }
        case 'Video': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              {<InvestigationVideo value={value as IMediaContentValue} />}
            </S.Column>
          ));
        }
        case 'Simulation': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              {<InvestigationSimulation value={value as IMediaContentValue} />}
            </S.Column>
          ));
        }
        case 'Button': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              {<InvestigationButton value={value as IButtonContentValue} />}
            </S.Column>
          ));
        }
        case 'UploadFinalReport':
        case 'Upload': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              {
                <InvestigationUpload
                  userId={block.userId}
                  currentContentIndex={currentContentIndex}
                  value={value as IUploadContentValue}
                  stepId={currentStepId}
                  investigationId={investigationId}
                  activityId={currentActivityId || currentActivity?.id || ''}
                  isReport={block.type === 'UploadFinalReport'}
                />
              }
            </S.Column>
          ));
        }
        case 'BestConceptReflection':
        case 'Reflection': {
          return (
            <InvestigationReflections
              submissionVersion={submissionVersion}
              values={block.values as IReflectionContentValue[]}
              previousSubmissionActivityId={previousSubmissionActivityId}
              activityId={currentActivityId || currentActivity?.id || ''}
              stepId={currentStepId ?? ''}
              userId={block.userId}
              classId={classId}
              investigationId={investigationId}
              type={block.type === 'BestConceptReflection' ? 'best-concept-reflection' : 'reflection'}
            />
          );
        }
        case 'ReflectionQuestionnaire': {
          return (
            <InvestigationQuestionnaireReflections
              values={block.values as IReflectionQuestionnaireContentValue[]}
              activityId={currentActivityId || currentActivity?.id || ''}
              stepId={currentStepId ?? ''}
              investigationId={investigationId}
              previousSubmissionActivityId={previousSubmissionActivityId}
              userId={block.userId}
              classId={classId}
            />
          );
        }
        case 'PeerReview': {
          return (
            <InvestigationReviews
              submissionVersion={submissionVersion}
              values={block.values as IPeerReviewContentValue[]}
              previousSubmissionActivityId={previousSubmissionActivityId}
              activityId={currentActivityId || currentActivity?.id || ''}
              dueDate={currentActivity?.dueDate || investigationDuedate}
              classStepId={currentStepId ?? ''}
              userId={block.userId}
              classId={classId}
              investigationId={investigationId}
              type="peer-review"
            />
          );
        }
        case 'PeerReviewQuestionnaire': {
          return (
            <InvestigationReviews
              submissionVersion={submissionVersion}
              values={block.values as IPeerReviewContentValue[]}
              previousSubmissionActivityId={previousSubmissionActivityId}
              activityId={currentActivityId || currentActivity?.id || ''}
              dueDate={currentActivity?.dueDate || investigationDuedate}
              classStepId={currentStepId ?? ''}
              userId={block.userId}
              classId={classId}
              investigationId={investigationId}
              type="peer-review-report"
            />
          );
        }
        case 'Comment': {
          return (
            <InvestigationComment
              updateHasWrittenComment={onCommentWrite}
              activityId={currentActivityId || currentActivity?.id}
              defaultActiveComment={defaultActiveComment}
              investigationId={investigationId}
              classId={classId}
              selectedStudentId={selectedStudentId}
              submissionVersion={submissionVersion}
            />
          );
        }
        case 'TextSubmit': {
          return (
            <InvestigationTextSubmit
              values={block.values as ITextSubmitContentValue[]}
              currentStepId={currentStepId}
              investigationId={investigationId}
              activityId={currentActivityId || currentActivity?.id || ''}
              userId={block.userId ?? selectedStudentId}
              isAssessment={isAssessment}
              isLocked={isLocked}
            />
          );
        }
        case 'BudgetCalculator': {
          return (
            <InvestigationBudgetCalculator
              key={currentActivityId || currentActivity?.id}
              values={block.values as IBudgetCalculatorContentValue[]}
              currentStepId={currentStepId}
              userId={block.userId}
              investigationId={investigationId}
              fullWidth
            />
          );
        }
        case 'Canvas': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              <InvestigationCanvas
                key={currentActivityId || currentActivity?.id}
                value={value as ICanvasContentValue}
                stepId={currentStepId}
                activityId={currentActivityId || currentActivity?.id || ''}
                canvasIndex={canvasIndex}
                investigationId={investigationId}
                userId={block.userId}
              />
            </S.Column>
          ));
        }
        case 'ProcessDragDropAnswer':
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              <AssessmentProccessBoard
                presentationMode={presentationMode}
                key={currentActivityId || currentActivity?.id}
                value={value as IProcessDragDropAnswer}
                onBoardSorted={onBoardSorted}
                currentStepId={currentStepId}
                investigationId={investigationId}
                activityId={currentActivityId || currentActivity?.id || ''}
                userId={block.userId ?? selectedStudentId}
                isLocked={isLocked}
              />
            </S.Column>
          ));
        case 'ProcessDragDropPictureAnswer':
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              <AssessmentProccessBoard
                presentationMode={presentationMode}
                key={currentActivityId || currentActivity?.id}
                value={value as IProcessDragDropAnswer}
                onBoardSorted={onBoardSorted}
                currentStepId={currentStepId}
                investigationId={investigationId}
                activityId={currentActivityId || currentActivity?.id || ''}
                userId={block.userId ?? selectedStudentId}
                isLocked={isLocked}
                isImageVersion={true}
              />
            </S.Column>
          ));
        case 'CategoryDrag':
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              <AssessmentCategoryBoard
                presentationMode={presentationMode}
                key={currentActivityId || currentActivity?.id}
                value={value as IProcessDragDropAnswer}
                onBoardSorted={onBoardSorted}
                currentStepId={currentStepId}
                investigationId={investigationId}
                activityId={currentActivityId || currentActivity?.id || ''}
                userId={block.userId ?? selectedStudentId}
                isLocked={isLocked}
              />
            </S.Column>
          ));
        case 'CategoryDragPicture':
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              <AssessmentCategoryBoard
                presentationMode={presentationMode}
                key={currentActivityId || currentActivity?.id}
                value={value as IProcessDragDropAnswer}
                onBoardSorted={onBoardSorted}
                currentStepId={currentStepId}
                investigationId={investigationId}
                activityId={currentActivityId || currentActivity?.id || ''}
                userId={block.userId ?? selectedStudentId}
                isLocked={isLocked}
                isImageVersion={true}
              />
            </S.Column>
          ));
        case 'SortingBoard': {
          return block.values.map((value, i) => (
            <S.Column span={span} key={`${currentContentIndex}${i}`}>
              <InvestigationSortingBoard
                key={currentActivityId || currentActivity?.id}
                value={value as ISortingBoardContentValue | ICategoryDragContentValue | IProcessDragDropAnswer}
                onBoardSorted={onBoardSorted}
                isCategoryDrag={block.type === 'CategoryDrag'}
                isProcessDrag={block.type === 'ProcessDragDropAnswer'}
                currentStepId={currentStepId}
                activityId={currentActivityId || currentActivity?.id || ''}
              />
            </S.Column>
          ));
        }
        case 'GroupBestConcept':
        case 'BestConceptPeerReview':
        case 'ConceptRating':
        case 'ConceptEvaluation': {
          return (
            <InvestigationReviews
              submissionVersion={submissionVersion}
              values={block.values as IConceptEvaluationContentValue[]}
              previousSubmissionActivityId={previousSubmissionActivityId}
              activityId={currentActivityId || currentActivity?.id || ''}
              dueDate={currentActivity?.dueDate || investigationDuedate}
              classStepId={currentStepId}
              userId={block.userId}
              classId={classId}
              investigationId={investigationId}
              type={
                block.type === 'ConceptEvaluation'
                  ? 'concept-evaluation'
                  : block.type === 'GroupBestConcept'
                  ? 'best-concept-of-group'
                  : block.type === 'BestConceptPeerReview'
                  ? 'peer-review-best-concept'
                  : 'concept-rating'
              }
            />
          );
        }
        case 'TextImage':
          return block.values.map((value, i) => (
            <S.Column
              span={Math.round(((value as ITextContentValue).ratio || 0.5) * 24)}
              style={{ justifyContent: 'center' }}
            >
              {
                <InvestigationTextImage
                  value={value as ITextContentValue | IMediaContentValue}
                  investigationType={investigationType}
                  contentSize={contentSize}
                />
              }
            </S.Column>
          ));
        case 'MultipleChoiceQuestion':
          return (
            <S.Column span={span} key={`MultipleChoiceQuestion`}>
              <MultipleChoiceQuestionPreview
                presentationMode={presentationMode}
                block={block}
                stepId={currentStepId}
                investigationId={investigationId}
                activityId={currentActivityId || currentActivity?.id || ''}
                userId={block.userId ?? selectedStudentId}
                onSelected={onSelected}
              />
            </S.Column>
          );
        case 'MultipleChoicePictureQuestion':
          return (
            <S.Column span={span} key="MultipleChoicePictureQuestion">
              <MultipleChoicePictureQuestionPreview
                presentationMode={presentationMode}
                block={block}
                stepId={currentStepId}
                investigationId={investigationId}
                activityId={currentActivityId || currentActivity?.id || ''}
                userId={block.userId ?? selectedStudentId}
                onSelected={onSelected}
              />
            </S.Column>
          );

        default:
          return <></>;
      }
    },
    [
      isAssessment,
      isLocked,
      currentActivity,
      currentActivityId,
      currentContentIndex,
      currentStepId,
      defaultActiveComment,
      investigationDuedate,
      investigationId,
      onCommentWrite,
      onBoardSorted,
      onSelected,
      selectedStudentId,
      classId,
      currentContent,
      investigationType,
      contentSize,
      previousSubmissionActivityId,
      submissionVersion,
      presentationMode,
      props.conciseForm,
    ],
  );

  const canChangeContent = useMemo(() => {
    if (!isStudent && !isGoogleStudent && !isCanvasStudent) return true;
    const hasCanvas = currentContent[currentContentIndex]?.blocks?.some((b) => b.type === 'Canvas');
    if (!hasCanvas) return true;

    return !currentContent[currentContentIndex]?.blocks?.some(
      (b) => b.type === 'Canvas' && !(b.values[0] as ICanvasContentValue)?.url,
    );
  }, [isStudent,isGoogleStudent, isCanvasStudent, currentContent, currentContentIndex]);

  useEffect(() => {
    if (currentActivityId) {
      window.scrollTo(0, 0);
    }
  }, [currentActivityId]);

  const needToConfirmBeforeProceeding =
    !isStudent && !isGoogleStudent && !isCanvasStudent && buildInPersonContent && !user.acknowledgedPresentationMessage && Boolean(classId);

  const activityContent = useMemo(
    () => (
      <>
        {preview && <S.PreviewBarrier />}
        <Row justify="center">
          <S.Column
            xs={16}
            md={presentationMode ? 20 : 18}
            xxl={presentationMode ? 21 : 20}
            $presentationMode={presentationMode}
          >
            <h1 data-cy="components-content-builder-content-title">{currentContent[currentContentIndex]?.title}</h1>
          </S.Column>
        </Row>
        <Row align="middle" justify="center">
          {currentContentIndex > 0 ? (
            <S.RoundButton
              data-cy="components-studentinvestigation-investigationcontentbuilder-round-left-button"
              onClick={() => setCurrentContentIndex(currentContentIndex - 1)}
              shape="circle"
              disabled={!canChangeContent}
              icon={<FiChevronLeft size={22} />}
            />
          ) : (
            <S.RoundButtonPlaceholder />
          )}
          <Col xs={16} md={18} xxl={presentationMode ? 21 : 20}>
            <S.Container>
              {isLocked && (
                <S.Overlay
                  data-cy="locked-assessment-overlay"
                  id="LockedAssessment"
                  title="This assessment is locked, you're not allowed to interact with it."
                />
              )}
              {currentContent[currentContentIndex]?.blocks.flat().map((b, i) => (
                <Row gutter={[15, 15]} key={i} style={{ marginBottom: 8 }}>
                {buildContentBlock(b)}
              </Row>
              ))}
            </S.Container>
          </Col>
          {showForwardButton ? (
            <S.RoundButton
              data-cy="components-studentinvestigation-investigationcontentbuilder-round-right-button"
              onClick={() => setCurrentContentIndex(currentContentIndex + 1)}
              $left
              shape="circle"
              disabled={!canChangeContent}
              icon={<FiChevronRight size={22} />}
            />
          ) : (
            <S.RoundButtonPlaceholder />
          )}
        </Row>
        {showProceedButton && (
          <Row justify="center" style={{ marginTop: 30 }}>
            <Tooltip title={isLocked ? null : cantProceedReason}>
              <Popconfirm
                disabled={!needToConfirmBeforeProceeding}
                getPopupContainer={(trigger) => trigger.parentElement || trigger}
                title={
                  <div style={{ maxWidth: 400 }}>
                    <p style={{ marginTop: 0, fontSize: 16, color: 'black' }}>
                      Since this stage is set to be conducted in person, proceeding will mark this activity as completed
                      for <b>all students</b> in this class.
                    </p>
                    <Checkbox
                      checked={dontShowAgainChecked}
                      onChange={(e) => setDontShowAgainChecked(e.target.checked)}
                    >
                      Don't show this again
                    </Checkbox>
                  </div>
                }
                onConfirm={() =>
                  onActivitySubmit && onActivitySubmit({ acknowledgedPresentationMessage: dontShowAgainChecked })
                }
              >
                <Col xs={16} md={presentationMode ? 20 : 18} xxl={presentationMode ? 21 : 20}>
                  {showNextStepButton && (
                    <Button
                      data-cy="components-studentinvestigation-investigationcontentbuilder-proceed-button"
                      text="Proceed to Next Step"
                      onClick={() => {
                       
                        if (!needToConfirmBeforeProceeding && onActivitySubmit) {
                          onActivitySubmit({ acknowledgedPresentationMessage: dontShowAgainChecked });
                         
                        }
                      }}
                      loading={loading}
                      block
                      disabled={!currentActivity?.completed && !canProceed}
                      minHeight={45}
                    />
                  )}
                </Col>
              </Popconfirm>
            </Tooltip>
          </Row>
        )}
      </>
    ),
    [
      isLocked,
      buildContentBlock,
      canProceed,
      currentActivity,
      currentContentIndex,
      loading,
      onActivitySubmit,
      preview,
      setCurrentContentIndex,
      showForwardButton,
      showProceedButton,
      currentContent,
      cantProceedReason,
      needToConfirmBeforeProceeding,
      dontShowAgainChecked,
      canChangeContent,
      presentationMode,
      showNextStepButton,
    
    ],
  );

  const messageBlockedActivity = useMemo(
    () => (
      <Result
        icon={
          <img
            data-cy="components-studentinvestigation-investigationcontentbuilder-clock-icon"
            src={timesupImage}
            alt="A clock surrounded by geometric forms"
            style={{ marginBottom: 16 }}
          />
        }
        title={`This stage begins on ${formatDateTime(currentStep?.startDate, 'EEEE, LLLL do')}`}
      />
    ),
    [currentStep],
  );
  return currentStep && moment(currentStep.startDate) > moment() ? messageBlockedActivity : activityContent;
};

export default InvestigationContentBuilder;
