import React from 'react'
import { useLocation } from 'react-router-dom';
import BookHeader from '../../components/Books/BookHeader';
import { useAuth } from '../../hooks/useAuth';
import AdvancedSearchHeader from '../AdvancedSearchHeader';
import LandingPageHeader from '../LandingPageHeader';
import AuthenticatedHeader from '../AuthenticatedHeader';

const MainHeader = () => {
    const { isLogged, isAdvancedSearch } = useAuth();
    const location = useLocation()
    const isLandingRoute = location.pathname === '/home' || location.pathname === '/test'
    const hasAuthenticatedHeader = isLogged && !isAdvancedSearch;
    const isBookRoute = location.pathname.startsWith('/book') || (location.pathname.startsWith('/investigation-presentation') && location.search.includes('source=book'))||location.pathname.startsWith('/cart');
    const isBookAdminRoute = location.pathname.startsWith('/bookadmin') || location.pathname.startsWith('/bookedit');
  return (
    <>
   {hasAuthenticatedHeader && !isLandingRoute &&  (isBookRoute?isBookAdminRoute?<AuthenticatedHeader />:<BookHeader/>:<AuthenticatedHeader />)}
        {isAdvancedSearch && <AdvancedSearchHeader />}
        {isLandingRoute && <LandingPageHeader/>}
    </>
  )
}

export default MainHeader
