import React, { useMemo, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { useAuth } from '../../../../hooks/useAuth';
import CreateClass from '../../../../shared/CreateClass';
import * as S from './styles';
import { Tooltip } from 'antd';
import { FiLock } from 'react-icons/fi';
import { getUserSubscription } from '../../../../shared/SubscriptionEndedModal';
import moment from 'moment';

const AddClassButton = () => {
  const [visible, setVisible] = useState(false);
  const { isFacilitator, user, activeRole } = useAuth();
  const userSubscription = getUserSubscription({ user, activeRole });
  const endDate = useMemo(() => {
    const stripeSubscription = user?.stripeSubscription;

    if (stripeSubscription?.endDate && user?.preferredSubscription !== 'organization') {
      return moment(stripeSubscription?.endDate, 'x').toDate().valueOf();
    }

    return userSubscription?.endDate || null;
  }, [user, userSubscription]);

  const subscriptionExpired = (endDate && endDate < Date.now()) || false;
  const subscriptionNotStarted = (userSubscription?.startDate && userSubscription.startDate > Date.now()) || false;

  const subscriptionActive = !(subscriptionExpired || subscriptionNotStarted);
  const activeSub = subscriptionActive && userSubscription !== undefined

  return (
    <>
      <S.Card
        onClick={() => activeSub && setVisible(true)}
        data-cy="components-classes-row-create-class-button"
        style={{
          position: 'relative',
          opacity: activeSub ? 1 : 0.6,
          cursor: activeSub ? 'pointer' : 'not-allowed'
        }}
      >
        <S.Icon>
          <BsPlus />
        </S.Icon>
        <S.Title>Create a New {isFacilitator ? 'Course or Event' : ' Class'}</S.Title>
        <S.Info>
          Create a {isFacilitator ? 'course or event' : ' class'} with {isFacilitator && 'workshop'} investigations
        </S.Info>
        <S.Info>spanning a whole year</S.Info>

        {!activeSub && (
          <Tooltip
            title={
              <>
                This feature allows you to create up to 8 classes in the Learning Hub. You can then add students to each class and assign them investigations and assessments to complete online.
                Unlock this feature with an{' '}
                <a
                  href="https://adilearninghub.com/account-type"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#1890ff' }}
                >
                  organizational subscription.
                </a>
              </>
            }
          >
            <span
              style={{
                position: 'absolute',
                top: '12px',
                right: '12px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                borderRadius: '50%',
                padding: '5px'
              }}
            >
              <FiLock size={18} color="#767676" strokeWidth={3} />
            </span>
          </Tooltip>
        )}

      </S.Card>

      <CreateClass visible={visible} setVisible={setVisible} />
    </>
  );
};

export default AddClassButton;
