import { useMutation } from '@apollo/client';
import { Col, message, Radio, Skeleton } from 'antd';
import React, { useCallback, useState, useMemo } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import Button from '../../../shared/Button';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import Sider from '../../../shared/Sider';
import Spacer from '../../../shared/Spacer';
import {
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationDisplay,
  InvestigationType,
  WorkShopTemplate,
} from '../../../types/investigation';
import { themeConfig } from '../../../utils/theme';
import { GQL_Mode } from '../../../types/investigation';
import NewInvestigationModal from '../../AdiTemplatesPage/NewInvestigationModal';
import * as S from './styles';

interface IInvestigationPresentationSider {
  investigation?: GQL_InvestigationDisplay;
  currentStepIndex?: number;
  currentActivityIndex?: number;
  classId?: string;
  onFinishSettings?: () => void;
  setCurrentStepByIndex?: (index: number) => void;
  setCurrentActivityByIndex?: (index: number) => void;
  currentPresentationMode?: GQL_Mode;
  setCurrentPresentationMode?: (mode: GQL_Mode) => void;
  firstSetting?: boolean;
  backUrl?: string;
  loading?: boolean;
  importInvestigation?: boolean;
  presentationSize?: string;
  setPresentationSize?: (size: string) => void;
  isBookRoute?:boolean
}

const InvestigationPresentationSider = (props: IInvestigationPresentationSider) => {
  const {
    investigation,
    currentStepIndex,
    currentActivityIndex,
    setCurrentStepByIndex,
    setCurrentActivityByIndex,
    firstSetting,
    onFinishSettings,
    backUrl,
    loading,
    importInvestigation,
    currentPresentationMode,
    setCurrentPresentationMode,
    presentationSize,
    setPresentationSize,
    isBookRoute,
  } = props;

  const lastSetting = (currentStepIndex ?? 0) + 1 === investigation?.steps?.length;
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const toogleModal = useCallback((open) => {
    setModalOpen(open);
  }, []);

  const type = history.location.pathname
    .split('/')
    .filter((each) => each.includes('-presentation'))[0]
    .split('-')[0];
  const isInvestigation = type === 'investigation';

  const cloneAction = useCallback(() => toogleModal(true), [toogleModal]);

  const stepMode = useMemo(() => {
    if (currentStepIndex !== undefined && currentStepIndex > -1) return investigation?.steps[currentStepIndex]?.mode;
  }, [currentStepIndex, investigation]);

  const [createInvestigationFromTemplate, { loading: loadingCreation }] = useMutation<{
    cloneInvestigation: GQL_InvestigationCatalogEntry;
  }>(gqlSchema.InvestigationSchema.mutations.DRAFT.cloneInvestigation, {
    onError: (err) => {
      message.error(err.message || 'Unexpected error');
    },
    onCompleted: ({ cloneInvestigation }) => {
      history.push(`/adi-investigations/edit/${cloneInvestigation.id}/core`);
    },
    refetchQueries: ['getInvestigationDrafts', 'getInvestigationCatalog'],
  });

  const handleUseTemplate = (name: string, type: InvestigationType, workShopTemplate?: WorkShopTemplate) => {
    createInvestigationFromTemplate({
      variables: {
        cloneinvestigationDraft: {
          id: investigation?.id,
          title: name,
          description: investigation?.description || 'Investigation Description',
          type,
          workShopTemplate,
        },
      },
    });
  };

  const handleSelectStage = (value: string) => {
    if (setCurrentStepByIndex) {
      const stepIndex = investigation?.steps?.findIndex((step) => step.id === value) ?? 0;
      setCurrentStepByIndex(stepIndex);
    }
  };

  const handleNextStage = () => {
    if (lastSetting && onFinishSettings) {
      onFinishSettings();
    } else if (setCurrentStepByIndex) {
      setCurrentStepByIndex((currentStepIndex ?? 0) + 1);
    }
  };

  const handlePreviousStage = () => {
    if (setCurrentStepByIndex) {
      setCurrentStepByIndex((currentStepIndex ?? 1) - 1);
    }
  };

  return (
    <Sider
      style={{ height: 'calc(100% - 80px)' }}
      title={
        loading || !investigation ? (
          <Skeleton.Input active />
        ) : (
          `${investigation?.discipline?.name}: ${investigation?.title}`
        )
      }
      backUrl={backUrl}
    >
      <S.SidebarContainer gutter={[0, 0]}>
        {!history?.location?.pathname?.includes('teacher-dashboard') && (
          <Col span={24}>
            <S.Divider />
            <S.SettingTitle>Preview Mode</S.SettingTitle>
            <Select
              data-cy="components-presentation-sider-preview-mode-selector"
              onChange={(v) => setCurrentPresentationMode?.(v as GQL_Mode)}
              placeholder="Loading..."
              value={currentPresentationMode}
            >
              {(stepMode === 'ALL' || stepMode === 'REMOTE') && (
                <SelectOption value="REMOTE" data-cy="components-presentation-sider-preview-mode-remote">
                  Remote
                </SelectOption>
              )}
              {(stepMode === 'ALL' || stepMode === 'INPERSON') && !isBookRoute &&(
                <SelectOption value="INPERSON" data-cy="components-presentation-sider-preview-mode-in-person">
                  In-Person
                </SelectOption>
              )}
            </Select>
            <Spacer size={24} />
          </Col>
        )}

        <Col span={24}>
          <S.Divider />
        </Col>

        {isInvestigation && (
          <>
            <Col span={24}>
              <Select
                data-cy="components-presentation-sider-stage-selector"
                onChange={(v) => handleSelectStage(v as string)}
                placeholder="Loading..."
                value={investigation?.steps?.length && investigation?.steps[currentStepIndex ?? 0]?.id}
              >
                {investigation?.steps?.map((step, index) => (
                  <SelectOption
                    value={step.id}
                    key={step.id}
                    data-cy={`components-presentation-sider-stage-selector-option-${index}`}
                  >
                    Stage {index + 1}: {step.name}
                  </SelectOption>
                ))}
              </Select>
            </Col>

            <Col span={24}>
              <Spacer size={32} />
            </Col>
          </>
        )}

        <Col span={24}>
          <S.SettingTitle>{isInvestigation ? 'Stage Activities' : 'Assessments'}</S.SettingTitle>
        </Col>

        {investigation?.steps[currentStepIndex ?? 0]?.activities?.map((activity, index) => (
          <Col span={24} key={activity.id}>
            <S.ActivityButton
              selected={currentActivityIndex === index}
              data-cy={`components-presentation-sider-activity-${index}`}
              onClick={() => setCurrentActivityByIndex && setCurrentActivityByIndex(index)}
            >
              {activity.name}
              <FiArrowRight size={22} />
            </S.ActivityButton>
          </Col>
        ))}

        <Col span={24}>
          <S.SettingTitle>Presentation Content Size</S.SettingTitle>
        </Col>

        <Col span={24}>
          <Radio.Group value={presentationSize} onChange={(e) => setPresentationSize?.(e.target.value)}>
            <Radio.Button data-cy="components-investigationpresentationpage-sider-content-size-large" value="large">
              Large
            </Radio.Button>
            <Radio.Button data-cy="components-investigationpresentationpage-sider-content-size-default" value="default">
              Default
            </Radio.Button>
            <Radio.Button data-cy="components-investigationpresentationpage-sider-content-size-small" value="small">
              Small
            </Radio.Button>
          </Radio.Group>
        </Col>

        <Col span={24}>
          <Spacer size={32} />
        </Col>

        {importInvestigation && (
          <Col span={24}>
            <Button
              text="Copy Investigation"
              theme={themeConfig.secondaryColor}
              minHeight={40}
              block
              onClick={cloneAction}
            />
          </Col>
        )}

        {isInvestigation && (
          <>
            <Col span={24}>
              <Button
                text="Previous Stage"
                theme={themeConfig.primaryOutlined}
                disabled={firstSetting}
                minHeight={40}
                block
                onClick={handlePreviousStage}
                data-cy="components-presentation-sider-previous-stage"
              />
            </Col>

            <Col span={24}>
              <Spacer size={16} />
            </Col>
          </>
        )}

        <Col span={24}>
          <Button
            text={lastSetting ? 'Finish' : 'Next Stage'}
            minHeight={40}
            block
            onClick={lastSetting ? () => history.goBack() : handleNextStage}
            data-cy="components-presentation-sider-next-stage"
          />
        </Col>

        <Col span={24}>
          <Spacer size={32} />
        </Col>
      </S.SidebarContainer>

      <NewInvestigationModal
        open={modalOpen}
        title={'Investigation'}
        onConfirm={handleUseTemplate}
        onClose={() => toogleModal(false)}
        loading={loadingCreation}
        subject={investigation?.discipline?.subject}
        isCloning={false}
      />
    </Sider>
  );
};

export default InvestigationPresentationSider;
