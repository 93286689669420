import styled from 'styled-components';
import { Badge, Layout, Menu as AntdMenu, Col as AntdCol, Row as AntdRow, Dropdown } from 'antd';
import { GrWorkshop } from 'react-icons/gr';
import { Modal as AntdModal } from 'antd';

const { Header: AntdHeader } = Layout;
const { SubMenu: SubMenuAntd } = AntdMenu;

export const HeaderBadge = styled(Badge) <{
  $visible?: boolean;
}>`
  box-shadow: ${(props) => (props.$visible ? 'rgba(0,0,0,0.3) 0px 0px 10px 1px;' : 'unset;')};
  padding: 11px;
  border-radius: 5px;
  vertical-align: middle;
   min-width: 40px;
  max-width: 100%;
  svg {
    font-size: 24px !important;
    min-width: 24px;
    min-height: 24px;
    vertical-align: text-top;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* Increase gap for better spacing */
  
  @media (max-width: 768px) {
    gap: 12px; /* Slightly larger gap for smaller screens */
  }
  
  /* Ensure consistent spacing for icons */
  .notification-icon {
    margin-right: 6px;
  }

  .message-icon {
    margin-right: 8px;
  }
`;

export const Column = styled.col`
`;

export const HeaderBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align items to the right */
  gap: 20px; /* Increase spacing between notification and other elements */
  flex-shrink: 0;
`;

export const AvatarContainer = styled.span<{ $fontSize: string }>`
  display: flex;
  align-items: center;
  margin-right: clamp(10px, 2vw, 20px); /* Responsive margin */

  .role-text {
    font-size: clamp(0.8em, 2vw, ${(props) => props.$fontSize}); /* Responsive font size */
    font-weight: 500;
    color: #231f20;
    margin-left: clamp(5px, 1vw, 8px); /* Responsive margin */
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
`;

export const ImgRole = styled.img`
  margin-left: 4px; /* Adjust as needed */
  width: 12px;
  height: 12px;

  @media (max-width: 768px) {
    margin-left: 2px; /* Adjust for smaller screens */
    width: 10px;
    height: 10px;
  }
`;

export const Header = styled(AntdHeader)`
  background-color: white;
  height: 80px;
  z-index: 1002;
  position: fixed;
  width: 100%;
  padding: 0;

  @media (max-width: 1200px) {
    padding: 0;
    display: flex;
    justify-content: flex-end;

  }
`;

export const Menu = styled(AntdMenu) <{
  $menuVisible?: boolean;
  userType?: "teacher" | "student" | "google-teacher" | "google-student" | "canvas-teacher" | "canvas-student"|"adi_super_admin";
}>`
  color: #767676;
  border: 0;
  width: auto;
  text-align: left;
  position: relative;
  max-width: 100vw;

  /* Default margin-left for different user types */
  margin-left: ${(props) => {
    switch (props.userType) {
      case "teacher":
        return "3%";
      case "google-teacher":
        return "20%";
      case "canvas-teacher":
        return "20%";
      case "student":
        return "60%";
      case "google-student":
        return "50%";
      case "canvas-student":
        return "50%";
      case "adi_super_admin":
        return "15%";
      default:
        return "0%";
    }
  }};

  @media (min-width: 1600px) {
    margin-left: ${(props) => {
    switch (props.userType) {
      case "teacher":
        return "25%";
      case "google-teacher":
        return "40%";
      case "canvas-teacher":
        return "40%";
      case "student":
        return "70%";
      case "google-student":
        return "60%";
      case "canvas-student":
        return "60%";
      case "student":
        return "70%";
      case "adi_super_admin":
        return "35%"
      default:
        return "0%";
    }
  }};
  }

  @media (min-width: 1920px) {
    margin-left: ${(props) => {
    switch (props.userType) {
      case "teacher":
        return "35%";
      case "google-teacher":
        return "50%";
      case "canvas-teacher":
        return "50%";
      case "student":
        return "70%";
      case "google-student":
        return "70%";
      case "canvas-student":
        return "70%";
      case "adi_super_admin":
        return "45%";
      default:
        return "0%";
    }
  }};
  }
`;



export const HeaderDropdown = styled(Dropdown)`
  top: 66px !important;
`;

export const Col = styled(AntdCol) <{
  $defaultAlign: string;
  $responsiveAlign: string;
}>`
  text-align: ${(props) => props.$defaultAlign};

  @media (max-width: 1200px) {
    text-align: ${(props) => props.$responsiveAlign};
    padding-right: 20px; /* Adjust padding for medium screens */
  }

  @media (max-width: 992px) {
    text-align: ${(props) => props.$responsiveAlign};
    padding-right: 15px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 768px) {
    text-align: ${(props) => props.$responsiveAlign};
    padding-right: 10px; /* Adjust padding for mobile screens */
  }
`;

export const ResponsiveMenuContainer = styled(AntdCol) <{
  $menuVisible: boolean;
}>`
  ${(props) => props.$menuVisible && 'box-shadow: 0 0px 0px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);'}
`;

export const Row = styled(AntdRow) <{ $menuVisible?: boolean }>`
  height: 100%;
  padding: 5px 0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  ${(props) => props.$menuVisible && 'padding-top: 8px;'}
`;

export const MenuItem = styled(AntdMenu.Item) <{ $menuVisible?: boolean }>`
  border: 0 !important;
  font-size: 15px;
  ${(props) => props.$menuVisible && 'padding-left: 26px !important;'}
  text-align: start;
  letter-spacing: 0.5px;
  &.ant-menu-item-selected {
    font-weight: bold;
    color: ${(props) => props.theme.primaryOutlined.color} !important;
    svg > path {
      stroke: ${(props) => props.theme.primaryOutlined.color} !important;
    }
  }

  &:hover {
    color: ${(props) => props.theme.primaryOutlined.color} !important;
    svg > path {
      stroke: ${(props) => props.theme.primaryOutlined.color} !important;
    }
  }

  svg {
    font-size: 24px !important;
    vertical-align: middle;
  }
`;

export const SubMenu = styled(SubMenuAntd)`
  border: 0 !important;
  font-size: 15px;
  margin: 0 20px !important;
  text-align: start;
  letter-spacing: 0.5px;
  &.ant-menu-item-selected {
    font-weight: bold;
    color: ${(props) => props.theme.primaryOutlined.color} !important;
    svg > path {
      stroke: ${(props) => props.theme.primaryOutlined.color} !important;
    }
  }

  &:hover {
    color: ${(props) => props.theme.primaryOutlined.color} !important;
    svg > path {
      stroke: ${(props) => props.theme.primaryOutlined.color} !important;
    }
  }

  svg {
    font-size: 24px !important;
    vertical-align: middle;
    margin-right: 10px;
  }
  &.submenucls > ul {
    padding: 10px !important;
    ul {
      padding: 10px;
    }
    li {
      margin: 0 !important;
    }
  }
`;

export const LogoImage = styled.img`
  height: 100%;
  padding: 8px;
  cursor: pointer;
  margin-left: 10px; /* Reduce excessive left margin */

  @media (max-width: 768px) {
    margin-left: 15px; /* Keeps logo positioned well on small screens */
    width: 85px;
  }
`;


export const RoundedIcon = styled.span`
  border-radius: 50%;
  border: 2px solid #767676;
  font-size: 24px !important;
  cursor: pointer;
  padding: 6px 8px;
  margin: 0 0 0 30px;
  svg {
    vertical-align: text-top;
    color: #767676;
    margin-top: 1px;
  }
`;

export const WorkshopIcon = styled(GrWorkshop)`
  path {
    stroke: #767676;
  }

  path:hover {
    stroke: ${(props) => props.theme.primaryOutlined.color} !important;
  }
`;

export const Container = styled.div`
  padding: 12px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0px;
  }
`;

export const Heading = styled.h1`
  text-align: center;
  margin-bottom: 24px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap onto multiple rows */
  gap: 16px; /* Space between cards */
  justify-content: center; /* Center cards horizontally */
`;

export const Card = styled.div`
  flex: 1 1 calc(33.333% - 32px); /* Adjust to fit 3 cards per row with gap */
  max-width: calc(33.333% - 32px); /* Adjust for card width with gap */
  box-sizing: border-box; /* Ensure padding is included in width */
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  /* Add any other styling needed */
  @media (max-width: 768px) {
    max-width: unset;
  }
`;

export const Icon = styled.div`
  font-size: 24px;
  margin-bottom: 8px;
`;

export const CardHeading = styled.h2`
  font-size: 18px;
  margin-bottom: 8px;
`;

export const CardParagraph = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;

export const ContactLink = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const SocialLink = styled.a`
  color: #333;
  font-size: 20px;
  &:hover {
    color: #007bff;
  }
`;

export const HelpIcon = styled.span`
  margin-left: 10px;
  margin-right: 20px;

  @media only screen and (max-width: 1200px) {
    margin-left: 15px;
    margin-right: 10px;
  }
`;

export const CardButton = styled.button`
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff; /* Primary color */
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;

  /* Ensure it appears within card */
  max-width: 100%;
  box-sizing: border-box;

  /* Remove default button border and appearance */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Add padding if needed */
  padding: 12px 24px;

  /* Button spacing */
  margin: 10px auto 0;

  /* Adjust font size */
  font-size: 16px;
`;

/* Add hover effect for the button */
export const CardButtonHover = styled(CardButton)`
  &:hover {
    background-color: #0056b3; /* Darker shade of primary color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

/* Add focus effect for the button */
export const CardButtonFocus = styled(CardButton)`
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5); /* Light blue outline */
  }
`;

/* Add disabled state for the button */
export const CardButtonDisabled = styled(CardButton)`
  &:disabled {
    background-color: #c0c0c0; /* Gray color */
    cursor: not-allowed;
  }
`;
//2nd Modal
export const ContainerK = styled.div`
  padding: 16px;
  width: 80%;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const HeadingK = styled.h1`
  font-size: 36px;
  color: #007bff;
  margin-bottom: 24px;
`;

export const SearchInputK = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const CardContainerK = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
`;

export const CardK = styled.div`
  flex: 1 1 calc(33.333% - 32px);
  max-width: calc(33.333% - 32px);
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  transition: box-shadow 0.3s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    max-width: unset;
    display: block;
  }

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &.selected {
    border: 2px solid #007bff;
  }
`;

export const IconK = styled.div`
  font-size: 40px;
  margin-bottom: 8px;

  img {
    width: 40px; // Adjust the size as needed
    height: 40px; // Adjust the size as needed
  }
`;

export const CardHeadingK = styled.h2`
  font-size: 18px;
  margin-bottom: 8px;
`;

export const CardParagraphK = styled.p`
  font-size: 14px;
  margin-bottom: 8px;
`;

export const CardButtonK = styled.button`
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #007bff;
  background-color: #fff;
  border: 2px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #007bff;
    color: #fff;
  }
`;

export const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    @media (max-width: 768px) {
      padding: 10px;
    }
  }
  .cutom-modal {
    width: 80% !important;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .ant-modal-header {
    border-bottom: none;
    padding: 0 16px;
  }

  .ant-modal-body {
    padding: 16px;
  }

  .ant-modal-footer {
    display: none; // Hide footer if not needed
  }

  .ant-modal-close {
    right: 16px; // Adjust close button position
  }
`;