import styled from 'styled-components';
import { Row, Col,Modal as AntModal  } from 'antd';

export const Container = styled.div`
  padding: 20px;
  background-color: #f0f2f5;
  min-height: 100vh;
`;

export const FilterRow = styled(Row)`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
`;

export const BookCard = styled(Col)`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  max-width:80%;
  max-height: 400px;
`;

export const ImageWrapper = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%; 
    height: 200px;
    border-radius: 4px; 
  }
`;

export const BookTitle = styled.h3`
  font-size: 14px; 
  margin-bottom: 8px;
  margin-top:6px;
  font-weight: bold;''
  line-height: 1.4; 
  height: 4.3em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const SearchInput = styled.input`
  width: 200px;
  height: 30px;
  border: 0.7px solid gray;
  box-shadow: none !important;
  flex-grow: 1; 
  padding-left: 30px;

  &:hover,
  &:focus {
    box-shadow: none !important;
    border: 0.5px solid lightblue;
    outline: none !important;
  }
`;

export const Modal = styled(AntModal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    background-color: #f0f2f5;
    border-radius: 8px 8px 0 0;
    border-bottom: none;
  }
  .ant-modal-title {
    font-size: 18px;
    font-weight: bold;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;