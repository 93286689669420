import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, message, Empty } from 'antd';
import * as S from './styles';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../../gql/schema';
import Button from '../../../../shared/Button';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import LibrarySkeleton from '../../Library/LibrarySkeleton';

const { Title } = Typography;

interface Book {
  id: number;
  title: string;
  grade: string;
  coverImage: string;
}
interface KitsRecommendationProps {
  productId: string;
}

const KitsRecommendation: React.FC<KitsRecommendationProps> = ({ productId }) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading, refetch } = useQuery(gqlSchema.ShopifySchema.queries.LIST.FetchKitsRecommendations, {
    variables: {
      bookId: productId,
      page: currentPage,
    },
    onError: (error) => {
      message.error(`Error in fetching kit recommendations - ${error.message || 'Unexpected Error'}`);
    },
  });
  useEffect(() => {
    refetch({
      bookId: productId,
      page: currentPage,
    });
  }, [currentPage, productId, refetch]);

  const kitData: Book[] = data?.fetchKitsRecommendations?.kitResponse?.map((kit: any) => ({
    id: kit.id,
    title: kit.title,
    price: kit.price,
    coverImage: kit.imageUrl[0] || '/path/to/default/image.jpg',
    description: kit.description || 'No description available',
  }));
  const totalPages = Math.ceil(data?.fetchKitsRecommendations.totalBooks / 4) || 1;

  const handlePageChange = (page: number) => setCurrentPage(page);
  return (
    <S.Container>
      <Row align="middle" style={{ height: 'auto' }}>
        <Col>
          <Title level={2}>You May Also Like Investigation Kits </Title>
        </Col>
        {kitData?.length === 0 ? (
          <div></div>
        ) : (
          <Row style={{ marginLeft: 'auto', gap: '8px' }}>
            <Col style={{ margin: 'auto' }}>
              <S.PrevButton
                disabled={currentPage <= 1}
                onClick={() => (currentPage > 1 ? handlePageChange(currentPage - 1) : null)}
              >
                <FaArrowLeft size={12} cursor="pointer" />
              </S.PrevButton>
            </Col>

            <Col style={{ margin: 'auto' }}>
              <S.NextButton disabled={currentPage >= totalPages} onClick={() => handlePageChange(currentPage + 1)}>
                <FaArrowRight size={12} cursor="pointer"/>
              </S.NextButton>
            </Col>
          </Row>
        )}
      </Row>
      {kitData?.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No investigation found"
          style={{ marginTop: '20px', alignSelf: 'center' }}
        />
      )}
      {loading ? (
        <LibrarySkeleton row={1} />
      ) : (
        <Row gutter={[16, 16]}>
          {kitData?.map((kit: any, idx: number) => (
            <Col key={`${kit.id}-${idx}`} xs={24} sm={12} md={6}>
              <S.BookCard>
                <S.ImageWrapper>
                  <img src={kit.coverImage} alt={kit.title} />
                </S.ImageWrapper>
                <S.BookTitle>{kit.title}</S.BookTitle>
                <Button
                  text="View"
                  style={{ backgroundColor: '#4367E9', color: '#fff' }}
                  onClick={() => {
                    history.push(`/bookPage/${kit.id}`);
                  }}
                  block
                />
              </S.BookCard>
            </Col>
          ))}
        </Row>
      )}
    </S.Container>
  );
};

export default KitsRecommendation;
