import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../gql/schema';

interface CartContextType {
    cartCount: number;
    refetchCart: () => void;
    updateCartCount: (newCount: number) => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { data, refetch } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getUserCart);
    
    const [cartCount, setCartCount] = useState(0);


    useEffect(() => {
        if (data?.getUserCart?.cartItem) {
            const totalItems = data.getUserCart.cartItem.reduce((total: number, item: { quantity: number }) =>
                total + item.quantity,
                0
            );
            setCartCount(totalItems);
        }
    }, [data]);

    const refetchCart = async () => {
        const { data } = await refetch();
        if (data?.getUserCart?.cartItem) {
            const totalItems = data.getUserCart.cartItem.reduce((total: number, item: { quantity: number }) =>
                total + item.quantity,
                0
            );
            setCartCount(totalItems);
        }
    };

    const updateCartCount = (newCount: number) => {
        setCartCount(newCount);
    };

    return (
        <CartContext.Provider value={{ cartCount, refetchCart, updateCartCount }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};