import React, { useCallback } from 'react';
import * as S from './styles';
import { ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import adiLearningHubSrc from '../../../assets/adi-learning-hub.svg';
import { MdEditDocument, MdFavoriteBorder } from 'react-icons/md';
import { CgMicrosoft } from 'react-icons/cg';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { RiAdminFill } from 'react-icons/ri';
import { CiShoppingCart } from 'react-icons/ci';
import StyleSwitch from './StyledSwitch';
import { useCart } from '../../../context';


const BookHeader = () => {
  const { signOut, isAdiSuperAdmin } = useAuth();
  const history = useHistory();
  const { cartCount } = useCart();




  const handleLogout = useCallback(() => {
    signOut();
    history.push('/');
  }, [signOut]);
  return (
    <S.HeaderContainer>
      <S.Logo>
        <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" onClick={() => history.push('/')} />
      </S.Logo>

      <S.NavMenu>
        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
          {/* Dashboard Link */}
          <Link
            to="/booklib"
            className="dashboard-link"
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              color: 'inherit',
              fontWeight: 'bold',
              fontSize: '16px',
              gap: '6px', 
            }}
          >
            <CgMicrosoft size={30} />
            <span>Dashboard</span>
          </Link>

          {/* Cart Link */}
          <Link
            to="/cart"
            className="cart-link"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              color: "inherit",
              fontWeight: "bold",
              fontSize: "16px",
              gap: "6px",
            }}
          >
            <div style={{ position: "relative", display: "flex", alignItems: "center" }}>
              <CiShoppingCart size={30} />
              {cartCount > 0 && (
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    backgroundColor: "#ff4d4f",
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "50%",
                    width: "18px",
                    height: "18px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 0 4px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  {cartCount}
                </span>
              )}
            </div>
            <span>Cart</span>
          </Link>



          {/* StyledSwitch (Properly Positioned) */}
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
            <StyleSwitch type="book" />
          </div>

          {/* Account Dropdown */}
          <S.AccountDropdown style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: '8px' }}>
              <UserOutlined className="icon" />
              My Account
            </div>
            <S.DropdownContent className="dropdown-content">
              <Link to="/bookprofile" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <UserOutlined className="icon" />
                My Profile
              </Link>

              {isAdiSuperAdmin && (
                <Link to="/bookadmin" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <RiAdminFill className="icon" />
                  Admin
                </Link>
              )}

              <Link to="/bookorderlist" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <ShoppingCartOutlined className="icon" />
                My Orders
              </Link>

              <Link to="/bookquoteList" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <MdEditDocument size={20} />
                My Quotes
              </Link>

              <Link to="/bookWishList" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <MdFavoriteBorder size={20} />
                My Favorites
              </Link>

              <Link
                to="#"
                className="logout-button"
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout();
                }}
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <UserOutlined className="icon" />
                Logout
              </Link>
            </S.DropdownContent>
          </S.AccountDropdown>

        </div>

      </S.NavMenu>


    </S.HeaderContainer>
  );
};

export default BookHeader;