import React  from 'react';
import { ToggleContainer, ToggleButton } from './SwitchStyle';
import { useHistory  } from 'react-router-dom';

interface StyledSwitchProps {
  type?: 'book' | 'subscription' | undefined;
}

const StyledSwitch: React.FC<StyledSwitchProps> = ({  type }) => {
  const history = useHistory();
  const handleClick = (path: string) => {
    history.push(path);
  };

  return (
    <ToggleContainer >
      <ToggleButton isActive={type ==='book'} onClick={() => handleClick('/booklib')} style={{ borderRadius: ' 100px 0 0 100px' }}>
        My Book View
      </ToggleButton>
      <ToggleButton isActive={type !=='book'} onClick={() => handleClick('/adi-dashboard')} style={{ borderRadius: ' 0px 100px 100px 0px' }}>
        My Subscription View
      </ToggleButton>
    </ToggleContainer>
  );
};

export default StyledSwitch;