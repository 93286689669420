import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Row, Col, message, Empty } from 'antd';
import AddClassButton from './AddClassButton';
import Item from './Item';
import DashboardRow from '../../../shared/DashboardRow';
import { gqlSchema } from '../../../gql/schema';
import { GQL_ClassResponse } from '../../../types/class';
import * as S from './styles';
import { useQuery } from '@apollo/client';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import { useAuth } from '../../../hooks/useAuth';
import board from '../../../assets/board.svg';
import SyncClassButton from './SyncClassButton';

interface IClassesRow {
  loaded: () => void;
  controller: any;
}

const ClassesRow: React.FC<IClassesRow> = ({ loaded,controller }) => {
  const [classesList, setClassesList] = useState<GQL_ClassResponse[]>([]);
  const [extraClassesList, setExtraClassesList] = useState<GQL_ClassResponse[]>([]);
  const { isTeacherOrFacilitator, isFacilitator, user } = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const supportedRosters = useMemo(() => ['Clever', 'Classlink','GoogleClassroom','Canvas'], []);
  const firstRowItemsLength = (isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) ? (supportedRosters.includes(user.source) ? 1 : 2) : 3;

  const { loading } = useQuery(gqlSchema.ClassSchema.query.CLASS.CLASSES.getClasses, {
    onCompleted: ({ getClasses }: { getClasses: GQL_ClassResponse[] }) => {
      // notify parent of load complete
      loaded();

      const classes = [...getClasses].sort((a, b) => (a.endDate > b.endDate ? -1 : 1));
      if (classes.length > firstRowItemsLength) {
        setClassesList(classes.slice(0, firstRowItemsLength));
        setExtraClassesList(classes.slice(firstRowItemsLength, classes.length));
      } else {
        setClassesList(classes);
      }
    },
    onError: (error) => {
      // notify parent of load complete
      loaded();

      message.error(
        error.message || 'There was an error loading your classes, check you connection and try again later.',
      );
    },
    context: {
      fetchOptions: {
        signal: controller?.signal,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const classes = useMemo(() => {
    if (!loading && !(isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) && !classesList?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No classes yet" />;
    }

    return (
      <Row gutter={[24, 24]}>
        {(isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher)  && (
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <AddClassButton />
          </Col>
        )}
        {/* {supportedRosters.includes(user.source) && (
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <SyncClassButton />
          </Col>
        )} */}
        {classesList.map((item: GQL_ClassResponse, i) => {
          return (
            <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
              <Item classObj={item} icon={<S.LogoImage src={board} alt="Class Icon" />} index={i} />
            </Col>
          );
        })}

        {loading && (
          <>
            <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard cardStyle="class" />
            </Col>
            {!supportedRosters.includes(user.source) && (
              <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
                <DashboardLoadingCard cardStyle="class" />
              </Col>
            )}
          </>
        )}
      </Row>
    );
  }, [isTeacherOrFacilitator,isGoogleTeacher, isCanvasTeacher, user, classesList, loading, supportedRosters]);
  const [extraClasses, setExtraClasses] = useState<ReactElement | null>(null);

  const onViewMoreClasses = useCallback(
    (opened: boolean) => {
      if (opened)
        setExtraClasses(
          <Row gutter={[24, 24]}>
            {extraClassesList.map((item: GQL_ClassResponse, i) => {
              return (
                <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
                  <Item classObj={item} icon={<S.LogoImage src={board} alt="Class Icon" />} index={i + 2} />
                </Col>
              );
            })}
          </Row>,
        );
      else setExtraClasses(null);
    },
    [extraClassesList],
  );

  return (
    <DashboardRow
      title={isFacilitator ? 'My Courses or Events' : 'My Classes'}
      items={classes}
      extraItems={extraClasses}
      onViewMore={onViewMoreClasses}
      showViewMore={extraClassesList.length > 0}
      extraCyTitle="classes"
      rightElement={supportedRosters.includes(user.source) ? <SyncClassButton /> : null}
    />
  );
};

export default ClassesRow;