import React, { useMemo } from 'react';
import { GiSandsOfTime } from 'react-icons/gi';
import { FiBarChart, FiBook, FiEdit, FiGrid, FiHome, FiLock, FiPenTool, FiUser, FiUsers } from 'react-icons/fi';
import * as S from './styles';
import { BsBook } from 'react-icons/bs';
import { BiBuildingHouse } from 'react-icons/bi';
import { Tooltip } from 'antd';

interface MenuProps {
  menuVisible: boolean;
  isWideScreen: boolean;
  currentPath: string;
  onSelectMenu: (item: any) => void;
}

interface MenuAdiAdminProps {
  menuVisible: boolean;
  currentPath: string;
  isWideScreen?: boolean;
  onSelectMenu: (item: any) => void;
}


export const RenderMenuStudent: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(() => {
    return (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
        userType="student"
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-studentmenu-item-myduedates"
          $menuVisible={menuVisible}
          icon={<GiSandsOfTime />}
          key="/student-dashboard/due-dates"
        >
          My Due Dates
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-studentmenu-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/student-dashboard"
        >
          Dashboard
        </S.MenuItem>

      </S.Menu>
    );
  }, [menuVisible, currentPath, onSelectMenu, isWideScreen]);
};

export const RenderMenuGoogleTeacher: React.FC<MenuProps & { user: any; isGoogleTeacher: boolean }> = ({
  menuVisible,
  currentPath,
  onSelectMenu,
  isGoogleTeacher,
  isWideScreen,
}) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
        userType="google-teacher"
      >
        <S.MenuItem $menuVisible={menuVisible} icon={<FiGrid />} key="/googleclassroom-teacher-dashboard">
          Dashboard
        </S.MenuItem>
        {/* {isGoogleTeacher && (
          <S.MenuItem icon={<FiDownloadCloud />} key={'/sync-list'}>
            Sync
          </S.MenuItem>
        )} */}
        <S.SubMenu title="Resources" icon={<FiEdit />} >
          <S.MenuItem
            data-cy="shared-authenticatedheader-teacher-item-investigation"
            $menuVisible={menuVisible}
            icon={<BsBook />}
            key="/teacher-investigation"
          >
            Lessons
          </S.MenuItem>
          <S.MenuItem
            data-cy="shared-authenticatedheader-teacher-item-assessments"
            $menuVisible={menuVisible}
            icon={<FiPenTool />}
            key="/teacher-assessment"
          >
            Assessments
          </S.MenuItem>
          <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/googleclassroom-teacher-assignment"
        >
          Assignment
        </S.MenuItem>
        </S.SubMenu>

      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen]
  );
};

export const RenderMenuGoogleStudent: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
        userType="google-student"
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/googleclassroom-students-dashboard"
        >
          Dashboard
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/googleclassroom-students-assignmentduedate"
        >
          Assignment Due Dates
        </S.MenuItem>

      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen],
  );
};

export const RenderMenuCanvasTeacher: React.FC<MenuProps & { isCanvasTeacher: boolean }> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen, isCanvasTeacher }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
        userType="canvas-teacher"
      >
        <S.MenuItem $menuVisible={menuVisible} icon={<FiGrid />} key="/canvas-teacher-dashboard">
          Dashboard
        </S.MenuItem>
        {/* {isCanvasTeacher && (
          <S.MenuItem icon={<FiDownloadCloud />} key={'/sync-list'}>
            Sync
          </S.MenuItem>
        )} */}
       <S.SubMenu title="Resources" icon={<FiEdit />} >
          <S.MenuItem
            data-cy="shared-authenticatedheader-teacher-item-investigation"
            $menuVisible={menuVisible}
            icon={<BsBook />}
            key="/teacher-investigation"
          >
            Lessons
          </S.MenuItem>
          <S.MenuItem
            data-cy="shared-authenticatedheader-teacher-item-assessments"
            $menuVisible={menuVisible}
            icon={<FiPenTool />}
            key="/teacher-assessment"
          >
            Assessments
          </S.MenuItem>
          <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/canvas-teacher-assignment"
        >
          Assignment
        </S.MenuItem>
        </S.SubMenu>



      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen, isCanvasTeacher],
  );
};

export const RenderMenuCanvasStudent: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
        userType="canvas-student"
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/canvas-students-dashboard"
        >
          Dashboard
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/canvas-students-assignmentduedate"
        >
          Assignment Due Dates
        </S.MenuItem>

      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen],
  );
};

export const RenderMenuTeacher: React.FC<MenuProps & { user: any; isWriter: boolean; isTeacher: boolean, isSubscriptionUser: boolean }> = ({
  menuVisible,
  currentPath,
  onSelectMenu,
  user,
  isWriter,
  isTeacher,
  isWideScreen,
  isSubscriptionUser
}) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={isWideScreen ? 'horizontal' : 'inline'}
        userType="teacher"
      >
        {user.subscription?.customLibrary && isWriter && (
          <S.MenuItem $menuVisible={menuVisible} icon={<FiBook />} key="/organization-library">
            Organization Library
          </S.MenuItem>
        )}

        {/* Resources Submenu */}
        <S.SubMenu title="Resources" icon={<FiEdit />}>
          <S.MenuItem
            data-cy="shared-authenticatedheader-teacher-item-investigation"
            $menuVisible={menuVisible}
            icon={<BsBook />}
            key="/teacher-investigation"
            disabled={!isSubscriptionUser}
          >
            Lessons {!isSubscriptionUser && (
              <Tooltip
                title={
                  <>
                    This feature allows you to access and use all the investigations and design challenges found in the Learning Hub.
                    If students complete the investigations in the Learning Hub, they can use the built-in peer review and feedback tools
                    as they work through a lesson, and you can use the scoring tool to assess their performance.
                    Unlock this feature with an{' '}
                    <a href="https://adilearninghub.com/account-type" target="_blank" rel="noopener noreferrer" style={{ color: '#1890ff' }}>
                      organizational subscription.
                    </a>
                  </>
                }
              >
                <span style={{ display: "inline-flex", alignItems: "center", transform: "translateY(-2px)" }}>
                  <FiLock size={16} color="#767676" strokeWidth={3} />
                </span>
              </Tooltip>


            )}
          </S.MenuItem>
          <S.MenuItem
            data-cy="shared-authenticatedheader-teacher-item-assessments"
            $menuVisible={menuVisible}
            icon={<FiPenTool />}
            key="/teacher-assessment"
            disabled={!isSubscriptionUser}
          >
            Assessments {!isSubscriptionUser && (
              <Tooltip
                title={
                  <>
                    This feature allows you to access and use all the assessments in the Learning Hub. Students can complete the assessments in the Learning Hub or you can download a handout for them to fill out. If students complete the assessments in the Learning Hub, you can use the analysis tool to make sense of their answers and the feedback tool to help your student improve. Unlock this feature with an{' '}
                    <a href="https://adilearninghub.com/account-type" target="_blank" rel="noopener noreferrer" style={{ color: '#1890ff' }}>
                      organizational subscription.
                    </a>
                  </>
                }
              >
                <span style={{ display: "inline-flex", alignItems: "center", transform: "translateY(-2px)" }}>
                  <FiLock size={16} color="#767676" strokeWidth={3} />
                </span>
              </Tooltip>

            )}
          </S.MenuItem>
        </S.SubMenu>

        {/* Other Menu Items */}
        {/* {['Clever', 'Classlink'].includes(user.source) && (
          <S.MenuItem icon={<FiDownloadCloud />} key="/sync-list">
            Sync
          </S.MenuItem>
        )} */}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-teacher-insights"
          $menuVisible={menuVisible}
          icon={<FiBarChart />}
          key="/teacher-insights/investigations"
          disabled={!isSubscriptionUser}
        >
          Insights  {!isSubscriptionUser && (
            <Tooltip
            title={
              <>
               This feature allows you to track student performance on investigations, design challenges, and assessments.  You can compare performance over time or across individuals, classes, standards and administrations (such as pre-post). Unlock this feature with an {' '}
                <a href="https://adilearninghub.com/account-type" target="_blank" rel="noopener noreferrer" style={{ color: '#1890ff' }}>
                  organizational subscription.
                </a>
              </>
            }
          >
            <span style={{ display: "inline-flex", alignItems: "center", transform: "translateY(-2px)" }}>
              <FiLock size={16} color="#767676" strokeWidth={3} />
            </span>
          </Tooltip>
          
          )}
        </S.MenuItem>
        {isTeacher && (
          <S.MenuItem
            data-cy="shared-authenticatedheader-teacher-item-teacher-users"
            $menuVisible={menuVisible}
            icon={<FiUser />}
            key="/teacher-users"
            disabled={!isSubscriptionUser}
          >
            Users{" "}
            {!isSubscriptionUser && (
             <Tooltip
             title={
               <>
                This feature allows you to add up to 40 students and 2 TAs to any class that you create in the Learning Hub. You can add students by sending them an email invite, by using your organization's SSO and rostering service (such as ClassLink or Clever) or by syncing the Learning Hub to your organizational LMS (such as Canvas or Google Classroom). Unlock this feature with an {' '}
                 <a href="https://adilearninghub.com/account-type" target="_blank" rel="noopener noreferrer" style={{ color: '#1890ff' }}>
                   organizational subscription.
                 </a>
               </>
             }
           >
             <span style={{ display: "inline-flex", alignItems: "center", transform: "translateY(-2px)" }}>
               <FiLock size={16} color="#767676" strokeWidth={3} />
             </span>
           </Tooltip>
           
            )}
          </S.MenuItem>

        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/teacher-dashboard"
        >
          Dashboard
        </S.MenuItem>

      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, user, isWriter, isTeacher, isWideScreen, isSubscriptionUser],
  );
};

export const RenderMenuTeacherAssistant: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/teacher-dashboard"
        >
          Dashboard
        </S.MenuItem>

      </S.Menu>
    ),
    [menuVisible, currentPath, onSelectMenu, isWideScreen],
  );
};

export const RenderMenuWriter: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem $menuVisible={menuVisible} icon={<FiBook />} key="/organization-library">
          Organization Library
        </S.MenuItem>

      </S.Menu>
    ),
    [menuVisible, currentPath, onSelectMenu, isWideScreen],
  );
};

export const RenderMenuAdiAdmin: React.FC<MenuAdiAdminProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
        userType='adi_super_admin'
      >
        <S.SubMenu key="resources" title="Resources" icon={<FiEdit />} >
          <S.MenuItem
            data-cy="shared-authenticatedheader-adimenu-item-books"
            $menuVisible={menuVisible}
            key="/bookadmin"
            icon={<BsBook />}
          >
            Books
          </S.MenuItem>
          <S.MenuItem
            data-cy="shared-authenticatedheader-adimenu-item-lessons"
            $menuVisible={menuVisible}
            key="/adi-investigations"
            icon={<FiBook />}
          >
            Lessons
          </S.MenuItem>
          <S.MenuItem
            data-cy="shared-authenticatedheader-adimenu-item-assessments"
            $menuVisible={menuVisible}
            key="/adi-assessments"
            icon={<FiPenTool />}
          >
            Assessments
          </S.MenuItem>

        </S.SubMenu>

        <S.SubMenu key="users" title="Users" icon={<FiUsers />} >
          <S.MenuItem
            data-cy="shared-authenticatedheader-adimenu-item-users"
            $menuVisible={menuVisible}
            key="/adi-users"
            icon={<FiUser />}
          >
            Individual
          </S.MenuItem>
          <S.MenuItem
            data-cy="shared-authenticatedheader-adimenu-item-organizations"
            $menuVisible={menuVisible}
            key="/adi-organizations"
            icon={<FiHome />}
          >
            Organizations
          </S.MenuItem>
        </S.SubMenu>

        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/adi-dashboard"
        >
          Dashboard
        </S.MenuItem>
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen],
  );
};

export const RenderMenuOrganizationAdmin: React.FC<MenuProps & { user: any }> = ({ menuVisible, currentPath, onSelectMenu, user, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        {user.subscription?.customLibrary && (
          <S.MenuItem
            data-cy="shared-authenticatedheader-profilemenu-item-organization-library"
            $menuVisible={menuVisible}
            icon={<FiBook />}
            key="/organization-library"
          >
            Organization Library
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-organization-users"
          $menuVisible={menuVisible}
          icon={<FiUsers />}
          key="/organization-users"
        >
          Users
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-organization-details"
          $menuVisible={menuVisible}
          icon={<BiBuildingHouse />}
          key="/organization-details"
        >
          Organization
        </S.MenuItem>
        {user.subscription?.reportPermission && (
          <S.MenuItem $menuVisible={menuVisible} icon={<FiGrid />} key="/organization-dashboard">
            Dashboard
          </S.MenuItem>
        )}

      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, user, isWideScreen],
  );
};