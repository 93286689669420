import { useMutation } from '@apollo/client';
import { message, Typography, Button } from 'antd';
import React, { useCallback, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../../gql/schema';
import { useAuth } from '../../../../hooks/useAuth';
// import * as S from './styles';
const { Text, Link } = Typography;

const SyncClassButton = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [submitSyncClasslinkClassesV2] = useMutation<{ syncClasslinkClassesV2: boolean }>(
    gqlSchema.ClassLinkSchema.mutations.ROSTER.syncClasslinkClassesV2,
    {
      onCompleted: (data) => {
        setLoading(false);
        message.success(
          <Text>
            Synchronization started,{' '}
            <Link onClick={() => history.push(`/sync/${data.syncClasslinkClassesV2}`)}>see details here</Link>, you'll
            be notified once the process finishes
          </Text>,
          5,
        );
      },
      onError: (error) => {
        setLoading(false);
        message.error(error.message);
      },
    },
  );

  const [submitSyncCleverClasses] = useMutation<{ syncCleverClasses: boolean }>(
    gqlSchema.CleverSchema.mutations.ROSTER.syncCleverClasses,
    {
      onCompleted: (data) => {
        setLoading(false);
        message.success(
          <Text>
            Synchronization started,{' '}
            <Link onClick={() => history.push(`/sync/${data.syncCleverClasses}`)}>see details here</Link>, you'll be
            notified once the process finishes
          </Text>,
          5,
        );
      },
      onError: (error) => {
        setLoading(false);
        message.error(error.message);
      },
    },
  );

  const [submitSyncCanvasClasses] = useMutation<{ syncCleverClasses: boolean }>(
    gqlSchema.CanvasSchema.mutations.CANVASCLASS.CanvasSync,
    {
      onCompleted: (data) => {
        setLoading(false);
        message.success(
          <Text>
            Synchronization started,{' '}
            <Link onClick={() => history.push(`/sync-list`)}>see details here</Link>, you'll be
            notified once the process finishes
          </Text>,
          5,
        );
      },
      onError: (error) => {
        setLoading(false);
        message.error(error.message);
      },
    },
  );
  const [submitSyncGoogleClassroomClasses] = useMutation<{ syncGoogleClasses: boolean }>(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.Sync,
    {
      onCompleted: (data) => {
        setLoading(false);
        message.success(
          <Text>
            Synchronization started,{' '}
            <Link onClick={() => history.push(`/sync-list`)}>see details here</Link>, you'll be
            notified once the process finishes
          </Text>,
          5,
        );
      },
      onError: (error) => {
        setLoading(false);
        message.error(error.message);
      },
    },
  );
  const source = user.source === 'Classlink' ? 'Sync with Classlink' :
    user.source === 'GoogleClassroom' ? 'Sync with Google Classroom' :
      user.source === 'Canvas' ? 'Sync with Canvas' :
        user.source === 'Clever' ? 'Sync with Clever' :
          ' ';



  const syncClassesV2 = useCallback(() => {
    setLoading(true); 
    // if (user.source === 'GoogleClassroom' || user.source === 'Canvas') {
    //   history.push('/sync-list');
    // }

    switch (user.source) {
      case 'Classlink':
        submitSyncClasslinkClassesV2();
        break;
      case 'Clever':
        submitSyncCleverClasses();
        break;
      case 'GoogleClassroom':
        submitSyncGoogleClassroomClasses();
        break;
      case 'Canvas':
        submitSyncCanvasClasses();
        break;
        default:
          setLoading(false); 
    }
  }, [
    submitSyncClasslinkClassesV2,
    submitSyncCleverClasses,
    submitSyncGoogleClassroomClasses,
    submitSyncCanvasClasses,
    user.source,
    history
  ]);

  return (
    <Button
      type="primary"
      icon={<FiRefreshCw style={{ fontSize: "18px", marginRight: "3px" }} />}
      onClick={syncClassesV2}
      size="large"
      loading={loading}
      style={{
        borderRadius: "25px",
        backgroundColor: "#4367E9",
        color: "#fff",
        fontWeight: 500,
        fontSize: "14px",
        height: "36px",
        padding: "8px 20px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        border: "none",
        marginLeft: "10px"
      }}
    >
      {source}
    </Button>
  );
};

export default SyncClassButton;