import React from 'react';
import { Modal, } from 'antd';
import Button from '../../../../shared/Button';
import { MdDelete } from 'react-icons/md';





const UpdateBookModal = ({
  isModalVisible,
  closeModal,
  addedData = [],
  setAddedData,
  setRemoveData,
  removedData = [],
  type = "Investigation Update",
  onSubmit,
}: any) => {


  const handleDelete = (type: string, id: string) => {
    if (type === "add") {
      const data = addedData.filter((i: any) => i.id !== id);
      setAddedData(data);
    } else {
      const data = removedData.filter((i: any) => i.id !== id);
      setRemoveData(data);
    }
  };

  return (
    <Modal title={type} visible={isModalVisible} onCancel={closeModal} footer={null} width={800}>
     
      <p
        style={{
          backgroundColor: "#00ff1575",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          color: "black",
          fontSize: "0.8rem",
          padding: "2px",
        }}
      >
        Added Data
      </p>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
        {addedData.length > 0 ? (
          addedData.map((item: any) => (
            <p key={item.id}>
              {item.title} <MdDelete onClick={() => handleDelete("add", item.id)} />
            </p>
          ))
        ) : (
          <p>No data added</p>
        )}
      </div>

      <p
        style={{
          backgroundColor: "#ff000075",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          color: "black",
          fontSize: "0.8rem",
          padding: "2px",
        }}
      >
        Removed Data
      </p>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
        {removedData.length > 0 ? (
          removedData.map((item: any) => (
            <p key={item.id}>
              {item.title} <MdDelete onClick={() => handleDelete("remove", item.id)} />
            </p>
          ))
        ) : (
          <p>No data removed</p>
        )}
      </div>

      <Button
        text="Update"
        style={{ backgroundColor: "#4367E9", color: "#fff" }}
        onClick={onSubmit}
        block
      />
    </Modal>
  );
};


export default UpdateBookModal;
