import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Col } from 'antd';
import * as S from './styles';
import Button from '../Button';
import { FiChevronDown, FiChevronUp, FiLock } from 'react-icons/fi';
import { themeConfig } from '../../utils/theme';
import styled from 'styled-components';
import SyncClassButton from '../../components/TeacherDashboard/ClassesRow/SyncClassButton';
import { getUserSubscription } from '../SubscriptionEndedModal';
import moment from 'moment';
import { useAuth } from '../../hooks/useAuth';

interface Props {
  title: string;
  items: ReactElement;
  extraItems?: ReactElement | null;
  onViewMore?: (opened: boolean) => void;
  showViewMore?: boolean;
  rightElement?: ReactElement | null;
  error?: string;
  extraCyTitle?: string;
}

const DashboardRow: React.FC<Props> = (props) => {
  const { title, items, extraItems, onViewMore, showViewMore, rightElement, extraCyTitle } = props;
  const [openMore, setOpenMore] = useState(false);
  const { user, activeRole,isStudent } = useAuth();
  const userSubscription = getUserSubscription({ user, activeRole });

  const endDate = useMemo(() => {
    const stripeSubscription = user?.stripeSubscription;
    if (stripeSubscription?.endDate && user?.preferredSubscription !== 'organization') {
      return moment(stripeSubscription?.endDate, 'x').toDate().valueOf();
    }
    return userSubscription?.endDate || null;
  }, [user, userSubscription]);

  const subscriptionExpired = (endDate && endDate < Date.now()) || false;
  const subscriptionNotStarted = (userSubscription?.startDate && userSubscription.startDate > Date.now()) || false;
  const subscriptionActive = !(subscriptionExpired || subscriptionNotStarted);
  const activeSub = subscriptionActive && userSubscription !== undefined;

  const betaViewMore = useCallback(() => setOpenMore((o) => !o), []);

  useEffect(() => {
    if (showViewMore && !extraItems && onViewMore) {
      onViewMore(true);
    }
  }, [showViewMore, extraItems, onViewMore]);

  const renderedItems = useMemo(() => {
    const Icon = openMore ? FiChevronUp : FiChevronDown;
    const id = title.toLowerCase().trim().replace(/ /g, '_').replace(/[^a-z]/gi, '');
    const extraItemsHeight = document.querySelector(`#${id} > div`)?.clientHeight || 0;

    return (
      <>
        <Col span={24}>{items}</Col>
        {showViewMore && (
          <>
            <OuterDiv $open={openMore} id={id} $height={openMore ? extraItemsHeight : 24}>
              <Col span={24}>{extraItems}</Col>
            </OuterDiv>
            <Col span={24}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -8 }}>
                <div style={{ width: '100%', height: '2px', backgroundColor: '#E0E0E2' }}></div>
                <Button
                  background="white"
                  icon={<Icon size={18} color={themeConfig.primaryColor.background} />}
                  shape="circle"
                  minWidth={40}
                  minHeight={40}
                  style={{ margin: '0 12px' }}
                  onClick={betaViewMore}
                />
                <div style={{ width: '100%', height: '2px', backgroundColor: '#E0E0E2' }}></div>
              </div>
            </Col>
          </>
        )}
      </>
    );
  }, [items, extraItems, openMore, betaViewMore, title, showViewMore]);

  return (
    <S.RowSP gutter={[24, 18]}>
      <Col span={20} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <S.Title>
          {title}
          {!activeSub && !isStudent && <FiLock size={18} color="gray" style={{ marginLeft: '8px' }} />}
        </S.Title>
        {title === 'My Classes' && rightElement && <SyncClassButton />}
      </Col>
      {showViewMore && !rightElement && (
        <Col span={4} className="view-more">
          <S.ViewMore
            onClick={betaViewMore}
            type="link"
            data-cy={`component-dashboard-row-view-more-button-${extraCyTitle}`}
          >
            {openMore ? 'View Less' : 'View More'}
          </S.ViewMore>
        </Col>
      )}
      {renderedItems}
    </S.RowSP>
  );
};

export default DashboardRow;

const OuterDiv = styled.div<{ $open: boolean; $height: number }>`
  overflow: hidden;
  height: ${(p) => p.$height}px;
  transition: height 0.25s ease-in, mask-image 5s ease-in;
  width: 100%;

  ${(p) =>
    !p.$open &&
    'mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));'};
`;
