import React, { useCallback, useState } from 'react';
import { Form as AntdForm, message, Typography, Col, Form } from 'antd';
import * as S from './styles';
import Input from '../../../shared/Input';
import Button from '../../../shared/Button';
import { ILoginProps } from '../../../types';
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { GQL_LoginResponse } from '../../../types/login';
import { useAuth } from '../../../hooks/useAuth';
import client from '../../../gql/api';
import adiLearningHubSrc from '../../../assets/adi-learning-hub.svg';

type Props = RouteComponentProps<null, any, any>;

const BookLogin = (props: Props) => {
  const history = useHistory();
  const { setSignedIn } = useAuth();
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [userData, setUserData] = useState<GQL_LoginResponse|undefined>();

  const [submitLogin, { loading: loginLoading }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.login,
    {
      onCompleted: async ({ login }: { login: GQL_LoginResponse }) => {
        setEmail(login.email);
        const { accessToken, ...data } = login;
        setToken(accessToken);
        setUserData(login);

        message.success("User Logged in successfully!");


        if (!login.isBookUser || (login.metaData === "{}"||login.metaData === null)) {
          setShowForm(true); // Show metadata form
        } else {
          setSignedIn({
            token: accessToken,
            user: { ...data, id: login.id},
          });          
          client.resetStore();
          history.push("/booklib");
        }

        
      },
      onError: (error) => {
        message.error(
          <Typography.Text data-cy="components-loginpage-error-text">
            {error.message}
          </Typography.Text>
        );
      },
    }
  );

  const [updateBookUser, { loading: updateLoading }] = useMutation(
    gqlSchema.BookSchema.mutations.UpdateBookUser,
    {
      onCompleted: () => {
        setShowForm(false);
        message.success("User metadata updated successfully!");
  
        if (userData) {
          setSignedIn({
            token: token,
            user: userData, 
          });
        }  
        client.resetStore();
        history.push("/booklib");
      },
      onError: (error) => {
        console.error(error);
        message.error("Failed to update user metadata.");
      },
    }
  );
  

  const onSubmitForm = useCallback(
    (values: ILoginProps) => {
      submitLogin({ variables: { data: values } });
    },
    [submitLogin]
  );

  const onSubmitBookUserForm = useCallback(
    (values: Record<string, unknown>) => {
      try {
        if (!values || Object.keys(values).length === 0) {
          throw new Error("Metadata is empty or invalid.");
        }

        updateBookUser({
          variables: {
            metadata: JSON.stringify(values),
            email,
          },
        });
      } catch (error) {
        message.error("There was an issue processing the metadata.");
      }
    },
    [updateBookUser, email]
  );

  return (
    <S.Container data-cy="components-loginpage-container">
      <S.RowSP justify="center" align="middle">
        <S.ColSP xs={24} sm={24} md={24} lg={20} xl={16} xxl={12}>
          <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" />
          <S.Title>Login To Your Account</S.Title>
          {/* <S.Title>Let’s Get Going. Login.</S.Title> */}
          <S.RowForm gutter={16} align="middle">
            <Col span={10} xs={24} md={24} xl={10} lg={10}>
              {showForm ? (
                <Form onFinish={onSubmitBookUserForm} layout="vertical">
                  <AntdForm.Item
                    label="Street Address "
                    name="jobDescription"
                    rules={[{ required: true, message: "Job Description is required" }]}
                  >
                    <Input placeholder="Enter your street address" />
                  </AntdForm.Item>
                  <AntdForm.Item
                    label="State"
                    name="state"
                    rules={[{ required: true, message: "State is required" }]}
                  >
                    <Input placeholder="Enter your state" />
                  </AntdForm.Item>
                  {/* <AntdForm.Item
                    label="Country"
                    name="country"
                    rules={[{ required: true, message: "Country is required" }]}
                  >
                    <Input placeholder="Enter your country" />
                  </AntdForm.Item> */}
                  <AntdForm.Item
                    label="City"
                    name="city"
                    rules={[{ required: true, message: "City is required" }]}
                  >
                    <Input placeholder="Enter your city" />
                  </AntdForm.Item>
                  <AntdForm.Item
                    label="Zip Code"
                    name="zipCode"
                    rules={[{ required: true, message: "Zip Code is required" }]}
                  >
                    <Input placeholder="Enter your zip code" type="number" />
                  </AntdForm.Item>
                  <AntdForm.Item>
                    <Button
                      text="Submit"
                      htmlType="submit"
                      loading={updateLoading}
                      block
                      style={{ width: "50%", margin: "0.5em auto" }}
                    >
                      Submit
                    </Button>
                  </AntdForm.Item>
                </Form>
              ) : (
                <Form onFinish={onSubmitForm} layout="vertical">
                  <AntdForm.Item
                    label="Email"
                    name="username"
                    rules={[
                      { required: true, type: "email", message: "Enter a valid email address" },
                    ]}
                  >
                    <Input placeholder="Type your email here..." />
                  </AntdForm.Item>
                  <AntdForm.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "Password is required" }]}
                  >
                    <Input type="password" placeholder="Type your password here..." />
                  </AntdForm.Item>
                  <AntdForm.Item>
                    <Button
                      text="Login"
                      htmlType="submit"
                      loading={loginLoading}
                      block
                      style={{ width: "50%", margin: "0.5em auto" }}
                    >
                      Login
                    </Button>
                  </AntdForm.Item>
                  <Typography.Text>
                    Don't have an account? <Link to="/bookSignup">Create an account</Link>
                  </Typography.Text>
                </Form>
              )}
            </Col>
          </S.RowForm>
        </S.ColSP>
      </S.RowSP>
    </S.Container>
  );
};


export default withRouter(BookLogin);