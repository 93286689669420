import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { Button, Empty, Layout, message } from 'antd';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';
import InvestigationContent from '../../shared/InvestigationContent';
import InvestigationNotes from '../../shared/InvestigationNotes';
import {
  GQL_InvestigationActivity,
  GQL_InvestigationCatalogEntry,
  GQL_InvestigationDisplay,
  GQL_InvestigationStep,
  GQL_Mode,
} from '../../types/investigation';
import { GQL_MeResponse } from '../../types/login';
import InvestigationContentBuild from '../StudentInvestigation/InvestigationContentBuilder';
import { InvestigationContentLoading } from '../StudentInvestigation/InvestigationLoading';
import InvestigationPresentationSider from './InvestigationPresentationSider';

type Props = RouteComponentProps<
  {
    classId?: string;
    type?: string;
    investigationId: string;
    submissionVersion?: string;
  },
  any,
  {
    backUrl?: string;
    bypassPresentationMode?: boolean;
  }
>;

const InvestigationPresentationPage: React.FC<Props> = (props) => {
  console.log("Inv Presentation Child Props: ", props);
  const { investigationId, classId } = props?.match?.params;
  const { backUrl, bypassPresentationMode } = props?.location?.state || {};
  const history = useHistory();
  const { updateUser } = useAuth();
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const [currentStep, setCurrentStep] = useState<GQL_InvestigationStep>();
  const [currentActivity, setCurrentActivity] = useState<GQL_InvestigationActivity>();
  const { isTeacherOrFacilitator, isOrganizationAdiAdmin, isWriter, isTeacherAssistant, user, isBookUser } = useAuth();
  const isGoogleTeacher = user?.preferredRole === 'google_teacher';
  const isCanvasTeacher = user?.preferredRole === 'canvas_teacher';
  const [investigation, setInvestigation] = useState<GQL_InvestigationDisplay | undefined>();
  const [presentationMode, setPresentationMode] = useState<GQL_Mode>();
  const [presentationSize, setPresentationSize] = useState<string>('default');
  const submissionVersion = props.match.params.submissionVersion ? parseInt(props.match.params.submissionVersion) : 1;
  const queryParams = new URLSearchParams(history.location.search);
  const queryParamsObject = Object.fromEntries(queryParams.entries());
  const [hasAccess, setHasAccess] = useState(false);
  const [isAccessLoading, setIsAccessLoading] = useState(true);



  const type = history.location.pathname
    .split('/')
    .filter((each) => each.includes('-presentation'))[0]
    .split('-')[0];

  const [fetchInvestigation, { loading: loadingInvestigation }] = useLazyQuery(
    gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById,
    {
      variables: { id: investigationId },
      fetchPolicy: 'network-only',
      onCompleted: ({ getInvestigationById }: { getInvestigationById: GQL_InvestigationDisplay }) => {
        if (getInvestigationById) setInvestigation(getInvestigationById);
      },
      onError: (error) => message.error(`There was an error loading the ${type}: ` + error.message),
    },
  );

  const [fetchInvestigationDraft, { loading: loadingInvestigationDraft }] = useLazyQuery<
    { getInvestigationDraftById: GQL_InvestigationCatalogEntry },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CREATION.getInvestigationDraftById, {
    variables: {
      id: investigationId,
    },
    fetchPolicy: 'network-only',
    onCompleted: ({ getInvestigationDraftById }) => {
      if (getInvestigationDraftById) {
        const inv: GQL_InvestigationDisplay = {
          id: getInvestigationDraftById.id,
          description: getInvestigationDraftById.description,
          classId: '',
          startDate: 0,
          dueDate: 0,
          explanation: getInvestigationDraftById.explanation,
          imageUrl: getInvestigationDraftById.imageUrl,
          title: getInvestigationDraftById.title,
          discipline: getInvestigationDraftById.discipline,
          standardsUrl: getInvestigationDraftById.standardsUrl,
          steps: getInvestigationDraftById.steps.map((st) => ({
            id: st.id,
            name: st.name,
            completed: false,
            startDate: 0,
            dueDate: 0,
            mode: st.mode || 'ALL',
            modesAvailable: ['ALL'],
            requiresPreviousStep: false,
            activities: st.activities.map((ac) => ({
              id: ac.id,
              stepId: ac.stepId,
              name: ac.name,
              completed: false,
              inPersonTeacher: ac.inPersonTeacher,
              remoteTeacher: ac.remoteTeacher,
              safety: ac.safety,
              help: ac.help,
              helpVideoUrl: ac.helpVideoUrl,
              teacherHelpVideoUrl: ac.teacherHelpVideoUrl,
              content: ac.content.map((co) => ({
                title: co.title,
                blocks: co.blocks.map((bl) => ({
                  type: bl.type,
                  columns: bl.columns,
                  values: bl.values,
                })),
              })),
              contentInPerson: ac.contentInPerson.map((co) => ({
                title: co.title,
                blocks: co.blocks.map((bl) => ({
                  type: bl.type,
                  columns: bl.columns,
                  values: bl.values,
                })),
              })),
              inPersonTeacherTips: ac.inPersonTeacherTips,
            })),
          })),
          questionnaire: [],
        };
        setInvestigation(inv);
      }
    },
    onError: (error) => message.error(`There was an error loading the ${type}: ` + error.message),
  });
  const isOnTeacherRoute = useMemo(() => history.location.pathname.includes('teacher-dashboard'), [history]);
  useEffect(() => {
    if ((isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher || isTeacherAssistant || isBookUser) && isOnTeacherRoute) fetchInvestigation();
    else fetchInvestigationDraft();
  }, [
    isTeacherOrFacilitator,
    isGoogleTeacher,
    isCanvasTeacher,
    fetchInvestigation,
    fetchInvestigationDraft,
    history,
    isOnTeacherRoute,
    isTeacherAssistant,
    isBookUser
  ]);

  useEffect(() => {
    if (investigation && investigation.steps?.length && investigation.steps[0].activities?.length) {
      setCurrentActivity(investigation.steps[0].activities[0]);
      setCurrentStep(investigation.steps[0]);
    }
  }, [investigation]);

  const [updateActivityStatus] = useMutation<null, { stepId: string; activityId: string; completed?: boolean }>(
    gqlSchema.InvestigationSchema.mutations.PROGRESS.completeActivityForClass,
    {
      onCompleted: () => {
        message.success("Saved Sucessfully");
      },
      onError: (err) => {
        if (err.message !== "Can't update status of activity that have submission block") {
          message.error('There was an error saving progress: ' + err.message || 'Unexpected Error');
        }
      },
      awaitRefetchQueries: false,
      refetchQueries: [
        {
          query: gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary,
          variables: { id: investigationId },
        },
      ],
    },
  );


  useQuery(
    gqlSchema.BookSchema.queries.checkBookOwner,
    {
      variables: { investigationId },
      skip: queryParamsObject.source !== "book",
      onCompleted: ({ checkBookOwner }: any) => {
        setHasAccess(!!checkBookOwner);
        setIsAccessLoading(false);
      },
      onError: (error: any) => {
        setHasAccess(false);
        setIsAccessLoading(false);
        message.error(
          `There was an error checking book ownership: ` + error.message
        );
      },
    }
  );
  const handleButtonClick = () => {
    if (history.location.pathname.includes("book")) {
      history.goBack();
    } else {
      history.replace("/booklib");
    }
  };

  useEffect(() => {
    if (queryParamsObject.source !== 'book') {
      setHasAccess(true)
    }
  }, [hasAccess])



  const [updateDontShowAgain, { loading: loadingDontShowAgain }] = useMutation<
    { changePresentationMessagePreferences: GQL_MeResponse },
    { dontShowAgain: boolean }
  >(gqlSchema.UserSchema.mutations.EDIT.changePresentationMessagePreferences, {
    onError: (err) => {
      message.error('There was an error saving progress: ' + err.message || 'Unexpected Error');
    },
    onCompleted: ({ changePresentationMessagePreferences }) => {
      updateUser(changePresentationMessagePreferences);
    },
  });

  const setCurrentStepByIndex = (index: number) => {
    if (investigation) {
      setCurrentActivity(investigation.steps[index]?.activities[0]);
      setCurrentStep(investigation.steps[index]);
    }
    setCurrentContentIndex(0);
  };

  const currentStepIndex =
    investigation?.steps.findIndex((step) => step.activities.some((activity) => activity.id === currentActivity?.id)) ||
    0;

  const currentActivityIndex =
    investigation?.steps[currentStepIndex || 0]?.activities?.findIndex(
      (activity) => activity.id === currentActivity?.id,
    ) || 0;

  const setCurrentActivityByIndex = (index: number, stepIndex?: number) => {
    if (investigation) {
      setCurrentActivity(investigation.steps[stepIndex ?? currentStepIndex].activities[index]);
    }
    setCurrentContentIndex(0);
  };

  const handleNextStepSubmit = async (payload?: { acknowledgedPresentationMessage?: boolean }) => {
    if (currentStepIndex === undefined || currentActivityIndex === undefined) return;
    const { acknowledgedPresentationMessage } = payload || {};

    if (investigation?.steps[currentStepIndex]?.mode === 'INPERSON' && investigation?.classId !== '') {
      await updateActivityStatus({
        variables: {
          activityId: investigation?.steps[currentStepIndex]?.activities[currentActivityIndex]?.id || '',
          stepId: investigation?.steps[currentStepIndex]?.id || '',
          completed: true,
        },
      });

      if (acknowledgedPresentationMessage) {
        await updateDontShowAgain({
          variables: {
            dontShowAgain: acknowledgedPresentationMessage,
          },
        });
      }
    }

    const nextActivityIndex = currentActivityIndex + 1;
    if (nextActivityIndex === investigation?.steps[currentStepIndex]?.activities?.length) {
      setCurrentActivityByIndex(0);
      setCurrentStepByIndex(currentStepIndex + 1);
    } else {
      setCurrentActivityByIndex(currentActivityIndex + 1);
    }
  };

  const handlePreviousStepSubmit = async () => {
    if (currentStepIndex === undefined || currentActivityIndex === undefined) return;
    const nextActivityIndex = currentActivityIndex - 1;
    if (nextActivityIndex === -1) {
      if (currentStepIndex - 1 === -1) return;
      const nextActivityIndex = (investigation?.steps?.[currentStepIndex - 1]?.activities?.length || 1) - 1;

      setCurrentStepByIndex(currentStepIndex - 1);
      setCurrentActivityByIndex(nextActivityIndex, currentStepIndex - 1);
    } else {
      setCurrentActivityByIndex(currentActivityIndex - 1);
    }
  };

  const currentStepMode = investigation?.steps[currentStepIndex ?? 0]?.mode;

  useEffect(() => {
    setPresentationMode(currentStepMode === 'ALL' ? 'REMOTE' : currentStepMode);
  }, [currentStepMode]);

  const contentList = presentationMode === 'INPERSON' ? currentActivity?.contentInPerson : currentActivity?.content;

  const handleStepChange = async ({ next }: { next: boolean }) => {
    const contentListLength = contentList?.length ?? 1;
    if (next) {
      if (currentContentIndex === contentListLength - 1) {
        handleNextStepSubmit();
      } else {
        setCurrentContentIndex(currentContentIndex + 1);
      }
    } else {
      if (currentContentIndex === 0) {
        handlePreviousStepSubmit();
      } else {
        setCurrentContentIndex(currentContentIndex - 1);
      }
    }
  };

  const loading = useMemo(() => loadingDontShowAgain || loadingInvestigation || loadingInvestigationDraft, [
    loadingDontShowAgain,
    loadingInvestigation,
    loadingInvestigationDraft,
  ]);

  return (
    (isAccessLoading && queryParamsObject.source === "book") ? (
      <InvestigationContentLoading />
    ) : hasAccess || (queryParamsObject.source !== "book") ? (
      <Layout>
        <InvestigationPresentationSider
          investigation={investigation}
          currentStepIndex={currentStepIndex}
          currentActivityIndex={currentActivityIndex}
          classId={classId}
          backUrl={backUrl || history.location.pathname.replace(`${type}-presentation`, `${type}-summary`)}
          firstSetting={currentStepIndex === 0 && currentActivityIndex === 0}
          setCurrentStepByIndex={setCurrentStepByIndex}
          setCurrentActivityByIndex={setCurrentActivityByIndex}
          currentPresentationMode={presentationMode}
          setCurrentPresentationMode={setPresentationMode}
          loading={loading}
          importInvestigation={(isOrganizationAdiAdmin || isWriter) && !isOnTeacherRoute}
          setPresentationSize={setPresentationSize}
          presentationSize={presentationSize}
          isBookRoute={queryParamsObject.source === "book"}
        />
        {loading ? (
          <InvestigationContentLoading />
        ) : (
          <InvestigationContent
            allowFullscreen
            presentationMode={!bypassPresentationMode}
            onContentChange={handleStepChange}
            contentSize={presentationSize}
          >
            <InvestigationContentBuild
              submissionVersion={submissionVersion}
              presentationMode
              currentContentIndex={currentContentIndex}
              currentActivity={currentActivity}
              currentStepId={currentStep?.id || ''}
              classId={investigation?.classId}
              setCurrentContentIndex={setCurrentContentIndex}
              investigationId={investigation?.id}
              buildInPersonContent={presentationMode === 'INPERSON'}
              onActivitySubmit={handleNextStepSubmit}
              canProceed={currentContentIndex + 1 === contentList?.length}
              loading={loading}
              investigationType={investigation?.type}
              contentSize={presentationSize}
            />
            <InvestigationNotes
              zIndex={1001}
              helpVideoUrl={currentActivity?.helpVideoUrl}
              teacherHelpVideoUrl={currentActivity?.teacherHelpVideoUrl}
              mode={presentationMode}
              {...{
                safety: currentActivity?.safety,
                inPersonTeacher: currentActivity?.inPersonTeacher,
                remoteTeacher: currentActivity?.remoteTeacher,
                inPersonTeacherTips: currentActivity?.inPersonTeacherTips,
              }}
            />
          </InvestigationContent>
        )}
      </Layout>
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="You do not own this book"
        style={{ marginTop: '20px', alignSelf: 'center' }}
      >
        <Button type="primary" onClick={handleButtonClick}>
          Go Back
        </Button>
      </Empty>
    )
  );

};

export default withRouter(InvestigationPresentationPage);
