import styled from 'styled-components';
import { Row } from 'antd';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  z-index: 1000;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); 
  top: 0;
  left: 0;
`;

export const ModalWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  padding: 20px;
`;

export const ContentRow = styled(Row)`
  display: flex;
  gap: 20px;
  margin-top: 16px;
  align-items: flex-start;
`;

export const LeftSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  img {
    width: 100%;
    max-width: 200px;
    height: auto;
    object-fit: contain;
    border-radius: 12px;
    border: 2px solid #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }
`;


export const RightSection = styled.div`
  flex: 2;
  display: grid;

  grid-template-areas: 
  "head head"
  "aside main"
  "foot foot1"
  "footer footer";
  column-gap: 12px;
  row-gap: 32px;
  align-items: start;
`;

export const BookDteailsWrapper = styled.div`

`;

export const DetailsTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  color: #333;
`;

export const DetailsText = styled.p`
  font-size: 14px;
  color:rgba(89, 89, 89, 0.77);
  margin: 0;
  word-wrap: break-word;
  font-weight: 550;
`;
export const Button = styled.button`
  width: 100%;
  max-width: 200px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  background: #4c6ef5;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #3b5bdb;
  }
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .ant-checkbox-wrapper {
    margin-bottom: 16px;
  }

  button {
    width: 100%;
    max-width: 200px;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    background: #4c6ef5;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background: #3b5bdb;
    }
  }
`;
export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  align-self: center;
  color: #333;
`;