import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Form as AntdForm, Input, Button, message } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';


const BookUserModal: React.FC = () => {
  const { user, isBookUser } = useAuth();
  const location = useLocation();
  const [form] = AntdForm.useForm();

  const bookModuleRoutesToExclude = [
    "/booklib",
    "/bookCode",
    "/bookDetails",
    "/bookPage",
    "/bookinvestigation",
    "/bookprofile",
    "/bookWishList",
    "/bookorderlist",
    "/bookorderdetail",
    "/bookquoteList",
    "/investigation-presentation",
  ];
  const [modalVisible, setModalVisble] = useState(false);

 
  useEffect(() => {
    if (isBookUser === undefined) return;
    
    const dismissed = localStorage.getItem("dismissedBookUserModal") === "true";
    const isExcludedRoute = bookModuleRoutesToExclude.includes(location.pathname);
    
    setModalVisble(!dismissed && isExcludedRoute && !isBookUser);
  }, [location.pathname, isBookUser]);
  

  const [updateBookUser, { loading: updateLoading }] = useMutation(
    gqlSchema.BookSchema.mutations.UpdateBookUser,
    {
      onCompleted: () => {
        message.success("User metadata updated successfully!");
        setModalVisble(false);
        window.location.reload();
      },
      onError: (error) => {
        console.error(error);
        message.error("Failed to update user metadata.");
      },
    }
  );

  const userEmail = user?.email;

  const onSubmitBookUserForm = useCallback(
    async (values: Record<string, unknown>) => {
      if (!values.jobDescription || !values.state || !values.city || !values.zipCode) {

        return;
      }
      try {
        await updateBookUser({
          variables: {
            metadata: JSON.stringify(values),
            email: userEmail,
          },
        });


      } catch (error) {
        message.error("There was an issue processing the metadata.");
      }
    },
    [updateBookUser, userEmail]
  );

  const handleIWillDoItLater = async () => {
    try {
      await updateBookUser({
        variables: {
          metadata: JSON.stringify({}),
          email: userEmail,
        }
      });
      localStorage.setItem("dismissedBookUserModal", "true");
      setModalVisble(false);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  



  if (isBookUser === undefined || !modalVisible) return null;

  return (
    <Modal
      title={null}
      visible={modalVisible}
      footer={null}
      closable={false}
      width={600}
      zIndex={1100}
      bodyStyle={{
        padding: '30px 40px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
      }}
    >
      {/* Modal Header */}
      <div
        style={{
          textAlign: 'center',
          paddingBottom: '15px',
          borderBottom: '1px solid #e0e0e0',
          marginBottom: '20px',
        }}
      >
        <h1 style={{ fontSize: '24px', fontWeight: 600, color: '#333', margin: 0 }}>
          Update Book User
        </h1>
        <p style={{ fontSize: '14px', color: '#666', marginTop: '5px' }}>
          Door to ADI Library
        </p>
      </div>

      {/* Form Section */}
      <AntdForm
        form={form}
        onFinish={onSubmitBookUserForm}
        layout="vertical"
        initialValues={{
          jobDescription: '',
          state: '',
          city: '',
          zipCode: '',
        }}
      >
        <AntdForm.Item label="Street Address" name="jobDescription" rules={[{ required: true, message: 'Please enter the street address' }]}>
          <Input placeholder="Enter street address" style={{ borderRadius: '6px' }} />
        </AntdForm.Item>
        <AntdForm.Item label="City" name="city" rules={[{ required: true, message: 'Please enter the city' }]}>
          <Input placeholder="Enter city" style={{ borderRadius: '6px' }} />
        </AntdForm.Item>
        <AntdForm.Item label="State" name="state" rules={[{ required: true, message: 'Please enter the state' }]}>
          <Input placeholder="Enter state" style={{ borderRadius: '6px' }} />
        </AntdForm.Item>
        <AntdForm.Item label="Zip Code" name="zipCode" rules={[{ required: true, message: 'Please enter the zip code' }]}>
          <Input placeholder="Enter zip code" type="number" style={{ borderRadius: '6px' }} />
        </AntdForm.Item>

        {/* Button Container */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Submit Button */}
          <AntdForm.Item style={{ marginBottom: 0, width: '48%' }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={updateLoading}
              style={{
                width: '100%',
                height: '40px',
                fontSize: '16px',
                fontWeight: 500,
                borderRadius: '8px',
                backgroundColor: '#4a6cf7',
                border: 'none',
                marginBottom: '15px',
              }}
            >
              Submit
            </Button>
          </AntdForm.Item>

          {/* "I will do it later" Button */}
          <AntdForm.Item style={{ marginBottom: 0, width: '48%' }}>
            <Button
              type="default"
              onClick={handleIWillDoItLater}
              style={{
                width: '100%',
                height: '40px',
                fontSize: '16px',
                fontWeight: 500,
                borderRadius: '8px',
                backgroundColor: '#f0f0f0',
                color: '#333',
                border: '1px solid #d9d9d9',
              }}
            >
              I will do it later
            </Button>
          </AntdForm.Item>
        </div>
      </AntdForm>
    </Modal>




  );
};

export default BookUserModal;
