import styled from 'styled-components';

interface FooterProps {
  bottom?: string;
}

export const Footer = styled.footer<FooterProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: ${(props) => props.bottom ? props.bottom : '0'};
  padding: 5px 15px 5px 15px;
  
  background-color: #4367E9;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto; 
`;

export const CopyrightText = styled.div`
  flex: 1;
  text-align: center;
  color: #fff;
  font-size:8px;
  font-weight: 500;
  @media (max-width: 1440px) {
      font-size: 11px;
    }
    @media (max-width: 1250px) {
      font-size: 10px;
    }
`;

export const FooterLinks = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    @media (max-width: 1440px) {
      font-size: 14px;
    }
    @media (max-width: 1250px) {
      font-size: 13px;
    }
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const IconLinks = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1150px) {
  max-width: 200px;
  }
  gap: 25px;
  
  a {
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
`;