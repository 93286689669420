
import React, { useMemo } from 'react';
import { Row, Col, Empty } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Item from './Item';
import AddClassButton from './AddClassButton';
import Subtract from '../../../assets/Subtract.svg';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import * as S from './styles';
import SyncClassButton from '../../TeacherDashboard/ClassesRow/SyncClassButton';

const CanvasClassRow: React.FC<{ 
  courses: any[]; 
  loading: boolean;
  currentPage: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  hasMorePages: boolean;
}> = ({ courses, loading, currentPage, itemsPerPage, onPageChange, hasMorePages }) => {

  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    onPageChange(currentPage + 1);
  };

  const classes = useMemo(() => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          {[...Array(itemsPerPage)].map((_, index) => (
            <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      );
    }

    if (courses.length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Classes" />;
    }

    return (
      <Row gutter={[24, 24]} style={{ marginTop: '24px' }}>
        {courses.map((course) => (
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={course.id}>
            <Item classObj={course} icon={<img src={Subtract} alt="Class Icon" />} />
          </Col>
        ))}
      </Row>
    );
  }, [courses, loading, itemsPerPage]);

  return (
    <>
      <Row justify="space-between" align="middle">
      <Col span={20} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <h2>My Classes</h2>
          <SyncClassButton/>
        </Col>
        <Col>
          <AddClassButton />
        </Col>
      </Row>
      <S.PaginationWrapper>
        <S.ArrowButton
          icon={<LeftOutlined />}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        />
        <S.ArrowButton
          icon={<RightOutlined />}
          onClick={handleNextPage}
          disabled={!hasMorePages}
        />
      </S.PaginationWrapper>
      {classes}
    </>
  );
};

export default CanvasClassRow;
